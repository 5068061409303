<!-- <ul id='timeline'>
<span *ngFor="let lData of timelineOuterLeftData" class="leftGrid smallGreyText" [pTooltip]="lData.placeholder" tooltipPosition="top">
  {{lData.value}}
</span>
<li class='event' *ngFor="let data of timelineData; let i = index"
  [ngClass]="{ 'last-list': i === timelineData.length-1 }"
  [ngStyle]="{'border-color': data?.lineActiveColor, 'minWidth': (data.valueBtwCircles === 0 || data.valueBtwCircles) ? setDistanceLineWidth(data.valueBtwCircles) : '150px'}">
  <div class='content'>
  </div>

  <div class="dot cursorPointer" (click)="op.toggle($event); cirlceClicked(data);">
    <span class='circle' [ngStyle]="{'border-color': data?.circleActiveTextColor, 'color': data?.circleActiveTextColor }">{{data?.valueInCircle}}</span>
  </div>
  <div class='date'>{{data?.textBtwCircles}}</div>
</li>
<span *ngFor="let rData of timelineOuterRightData" class="rightGrid" [pTooltip]="rData.placeholder" tooltipPosition="top">
  {{rData.value}}
</span>
</ul>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
  <div *ngFor="let data of timelineData">
    <p-card header="" [style]="{'width': '100%', 'margin': '5px 0'}" *ngIf="showClickedContentValue === data.valueInCircle">
      <ng-template pTemplate="header"></ng-template>
      <p *ngFor="let content of data.contents">
        <span style="margin-right: 3px;">{{content.label}} <span *ngIf="content.label">:</span> </span>
        <span style="font-weight: 500" [ngClass]="{'custom-badge ' : content?.id}" [ngStyle]="orderStatuColorService.getTextAndBorderColor(content.id, content.field)">{{content.value}}</span>
      </p>
    </p-card>
  </div>
</p-overlayPanel> -->
<div class="timeline-container">
  @for (lData of timelineOuterLeftData; track lData.value) {
    <p
      [pTooltip]="lData.placeholder"
      tooltipPosition="top"
      class="timeline-left-text"
    >{{ lData.value }}</p>
  }
  <ul id='timeline'>
    @for (data of timelineData; track data.valueInCircle; let i = $index) {
      <li class='event'
          [ngClass]="{ 'last-list': i === timelineData.length-1 }"
          [ngStyle]="{'border-color': data?.lineActiveColor, 'width': (data.valueBtwCircles === 0 || data.valueBtwCircles) ? setDistanceLineWidth(data.valueBtwCircles) : '150px'}">
        @if (extractedEtaDetails?.[data?.valueInCircle]) {
          <div class='content-eta-info' [ngStyle]="(i === timelineData.length-1) && {'display':'none'}"
               [pTooltip]="'ETA : ' +extractedEtaDetails?.[data?.valueInCircle]" tooltipPosition="top">
            <p>{{ extractedEtaDetails?.[data?.valueInCircle] }}</p>
          </div>
        }
        @if (extractedEtaDetails?.[data?.valueInCircle]) {
          <div class='content-eta-info-indicator' [ngStyle]="(i === timelineData.length-1) && {'display':'none'}"
          >
          </div>
        }
        <div class="dot cursorPointer"
             (click)="overlayPanelVisible && op.hide();!overlayPanelVisible && op.show($event); cirlceClicked(data);">
				<span class='circle' [ngClass]="{'no-background': data?.dataMissing }" [ngStyle]="{'border-color': data?.circleActiveTextColor,
				 'color': data?.circleActiveTextColor }">{{data?.valueInCircle}}</span>
        </div>
        <div [pTooltip]="data?.distanceTooltip || ''" tooltipPosition="bottom" class='date'
             [ngStyle]="(i === timelineData.length-1) && {'display':'none'}">{{ data?.textBtwCircles }}
        </div>
      </li>
    }
  </ul>
  @if(statusInitial()){
  <p pBadge [value]="statusInitial()" class="order-status-info"
    [style]="{'--status-color':(childStatus() | statusToColorPipe)}" tooltipEvent="hover"
    [pTooltip]="(childStatus() | namingConvention)" tooltipPosition="top">
  </p>
  }
  <div class="timeline-right-container">
    @for (rData of timelineOuterRightData; track rData.value; let j = $index) {
      <p
        [attr.data-status]="rData.placeholder"
        [ngStyle]="j === 0? {'margin-left':'0'}:{'margin-left':'12px'}"
        [pTooltip]="rData.placeholder"
        class="timeline-right-text"
        tooltipPosition="top">
      {{rData.value}}</p>
    }
  </div>
</div>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}" (onHide)="overlayPanelVisible=false" (onShow)="overlayPanelVisible=true">
  @for (data of timelineData; track data.valueInCircle) {
    <div>
      @if (showClickedContentValue === data.valueInCircle) {
        <div header="" [style]="{'width': '100%', 'margin': '5px 0'}">
          @for (content of data.contents; track content.label) {
            <p>
              <span style="margin-right: 3px;">{{ content.label }} @if (content.label) {
                <span>:</span>
              } </span>
              <span style="font-weight: 500" [ngClass]="{'custom-badge ' : content?.id}"
                    [ngStyle]="orderStatuColorService.getTextAndBorderColor(content.id, content.field)">{{ content.value }}</span>
            </p>
          }
        </div>
      }
    </div>
  }
</p-overlayPanel>
