import {Component, Input, OnInit} from '@angular/core';
import {AppMainComponent} from 'app/core/main/app.main.component';
import {select, Store} from '@ngrx/store';
import {NotificationActions} from 'state/Notification';
import {Notifications} from 'state/Notification/interfaces/INotification.modal';
import {getNotificationList} from 'state/Notification/notification.selector';
import {SocketActions} from 'state/Socket';
import {CONSTANT} from 'config/constant';
import {AppState} from "state/app.state";

@Component({
  selector: 'app-rightpanel',
  templateUrl: './app.rightpanel.component.html',
  styles: [`.date {
    color: grey !important;
    float: right;
    font-size: 11px !important;
    font-weight: 500;
    margin-top: 3px
  }

  .M5 {
    margin: 5px;
  }

  :host ::ng-deep .p-badge-info {
    padding: 0;
    font-size: 10px !important
  }

  .notifications-enabler {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 7px !important
  }`]
})
export class AppRightPanelComponent implements OnInit  {

  notifications: Notifications;
  notificationList$ = this.store.pipe(select(getNotificationList));

  @Input() pushNotificationsEnabled: boolean

  constructor(
    public app: AppMainComponent,
    private store: Store<AppState>
  ) {
    
  }

  ngOnInit() {
    this.store.dispatch(NotificationActions.getAllNotifications());
    this.store.dispatch(SocketActions.userNotificationWebSocketRequired({
      nameSpace: CONSTANT.SOCKET.USER_NOTIFICATIONS.NAMESPACE.NOTIFICATION,
      topicName: CONSTANT.SOCKET.USER_NOTIFICATIONS.TOPICS.LIVE
    }));
    this.store.dispatch(SocketActions.updateSocketDataRequired({
      nameSpace: CONSTANT.SOCKET.TRACKER.NAMESPACE.GEOFENCE,
      topicName: CONSTANT.SOCKET.TRACKER.TOPICS.GEO_FENCE_CHECK,
      key: 'geo_fence_check',
      callback: this.constructGeoFenceNotification,
    }));
  }


  toggleNotificationsEnabler() {
    this.store.dispatch(NotificationActions.toggleNotificationEnabler({enableStatus: this.pushNotificationsEnabled}))
  }

  // TODO: Need to show in notification tab. (Its not handled)
  constructGeoFenceNotification = (geoFenceCheck) => {
    let notificationObject: any;

    let message = "";
    let topic = "";
    let timeStamp = "";
    if (
      geoFenceCheck &&
      geoFenceCheck.geoFence_in &&
      geoFenceCheck.geoFence_in.status
    ) {
      message =
        "Car with registration number " +
        (geoFenceCheck.assetObj && geoFenceCheck.assetObj.registrationNumber
          ? geoFenceCheck.assetObj.registrationNumber
          : "-") +
        " entered geo fence " +
        (geoFenceCheck.geoFenceObj && geoFenceCheck.geoFenceObj.label
          ? geoFenceCheck.geoFenceObj.label
          : "-");
      topic = "Geo Fence In";
      timeStamp = geoFenceCheck.geoFence_in.timeStamp
        ? geoFenceCheck.geoFence_in.timeStamp
        : "";
     // this.notificationCount++;
    } else if (
      geoFenceCheck &&
      geoFenceCheck.geoFence_out &&
      geoFenceCheck.geoFence_out.status
    ) {
      message =
        "Car with registration number " +
        (geoFenceCheck.assetObj && geoFenceCheck.assetObj.registrationNumber
          ? geoFenceCheck.assetObj.registrationNumber
          : "-") +
        " exit geo fence " +
        (geoFenceCheck.geoFenceObj && geoFenceCheck.geoFenceObj.label
          ? geoFenceCheck.geoFenceObj.label
          : "-");
      topic = "Geo Fence Out";
      timeStamp = geoFenceCheck.geoFence_out.timeStamp
        ? geoFenceCheck.geoFence_out.timeStamp
        : "";
     // this.notificationCount++;
    }
    // eslint-disable-next-line prefer-const
    notificationObject = {
      topic: topic,
      message: message,
      timeStamp: timeStamp,
    };
    if(notificationObject){
      
    }
   // return notificationObject;
  }

}
