import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {cloneDeep, forEach, remove, sortBy} from 'lodash';
import {ConfirmationService, LazyLoadEvent, MenuItem, SortEvent} from 'primeng/api';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import moment from 'moment-timezone';
import {IotzenPillType} from '../iotzen-pills/iotzen-pill-type';
import {KioskStore} from '../../kiosk/models/store.model';
import {Store} from '@ngrx/store';
import {updateDateRangeFieldDefaultFilterValue, updateOrderTableHeaderDataIsColumSeen} from '../../../state/Assets/assets.action';
import {getTableFilterDate} from '../../../state/Settings/settings.selector';
import { CONSTANT } from '../../../config/constant';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import { isMobileViewSelector } from '../../../state/Assets/assets.selector';
import { setSelectedIdToRowHighlight } from '../../../state/Sidebar/sidebar.action';
import { selectedRowId } from '../../../state/Sidebar/sidebar.selector';
import { TableFilter } from '../../../state/Settings/interfaces/orderSettings.interface';


const EXPECTED_DEL_DATE = CONSTANT.TABLE.EXPECTED_DELIVERY_DEFAULT_SORT_FIELD_NAME;

@Component({
  selector: 'app-primeng-table',
  templateUrl: './primeng-table.component.html',
  styleUrls: ['./primeng-table.component.scss']
})
export class PrimengTableComponent implements OnInit, OnChanges {
  constructor(private confirmationService: ConfirmationService, private store: Store,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,) {
    this.showCheckbox = true;
    this.selectionMode = 'multiple';
    this.sortingOrder = 1;
    this.loadPillArrBuildOnce = true;
  }

  modernSelectedRows: any = [];
  defaultSelectedRow: any;
  sortByColName: string;
  isSortedSearch: boolean;
  isSortedBtnClicked: boolean;
  lazyLoadedObject: LazyLoadEvent;
  clickedBtnConfig: TableBtn;
  dataViewType: string;
  clickedRowData: any;
  showAllOrdersToBeServed = false;
  showFilterPills:boolean = false
  @Input() selectButtonValue: string;

  @Input() selectedStore: any;
  @Input() orderTableHeaderArray: any = [];
  @Input() orderTableHeaderObj: any = {};
  @Input() buttonConfigObjArray: Array<TableBtn> = [];
  @Input() allAssets: any;
  @Input() defaultSortFieldName: string;
  @Input() sortingOrder: number;
  @Input() resetModernSelectedRows: number;
  @Input() sessionStorageName: string;
  @Input() msgToShowInBtnPopup: string;
  @Input() msgToShowInActionClickedPopup: string;
  @Input() showCheckbox: boolean;
  @Input() arrayOfDataViewTypePills: Array<IotzenPillType> = [];
  @Input() dropdownBtnConfig: any;
  @Input() selectionMode: string;
  @Input() isSetFirstValAsDefaultVal: boolean;
  @Input() toggleBtnMsg: string;
  @Input() orderStatusIsEditable = false;
  @Input() showTodayPlacedOrderButton = false;
  @Input() dropDownStyleClass = '';
  @Input() isTableHasStateMng: unknown;
  @Input() showColumnSelectorDropdown: boolean = true
  @Input() isTableSearchable: boolean = false;
  @Input() autoRefreshBtnConfig:IAutoRefreshOrdersBtnConfig;

  @Output() sendSortByFieldObj: EventEmitter<any> = new EventEmitter();
  @Output() sendClickedRowDataToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendModernSelectedRows: EventEmitter<any> = new EventEmitter();
  @Output() sendLazyLoadedObject: EventEmitter<LazyLoadEvent> = new EventEmitter();
  @Output() sendDataToRemove: EventEmitter<any> = new EventEmitter();
  @Output() sendClickedTableBtnToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendPopupConfirmationEventToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendPopupCancelEventToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendPopupConfirmationActionEventToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendDataViewTypeEventToMainComp: EventEmitter<any> = new EventEmitter();
  @Output() sendSelectedStore: EventEmitter<any> = new EventEmitter();
  @Output() sendCheckedToggleButton: EventEmitter<any> = new EventEmitter();
  @Output() sendTableChipRemoveData: EventEmitter<any> = new EventEmitter();
  @Output() sendPaymentStatusModalChange: EventEmitter<any> = new EventEmitter();
  @Output() sendOrderStatusModalChange: EventEmitter<any> = new EventEmitter();
  @Output() sendDisplayPlacedOrder: EventEmitter<any> = new EventEmitter();
  @Output() sendEditedCellValue: EventEmitter<any> = new EventEmitter();
  @Output() sendCheckoutDataForPrint: EventEmitter<any> = new EventEmitter();
  @Output() sendRefundStart: EventEmitter<any> = new EventEmitter();
  @Output() sendCancelOrder: EventEmitter<any> = new EventEmitter();
  @Output() emitActionMethod: EventEmitter<any> = new EventEmitter();
  @Output() sendOnSearchValue: EventEmitter<any> = new EventEmitter();
  @Output() sendPrintSokReceipt: EventEmitter<any> = new EventEmitter();
  @Output() sendSelectedAction: EventEmitter<any> = new EventEmitter();
  @Output() reSuffleRoutePlanData: EventEmitter<any> = new EventEmitter();
  @Output() emittGetNewOrdersAction: EventEmitter<void> = new EventEmitter();


  seenOrderTableHeaderArray: any = [];
  moreInfoOverlayVisible = false;
  selectedOrderData: any;
  selectedFilterPillsArray: any = [];
  actionMenuItems: MenuItem[];
  routePlanActionMenuItem : MenuItem[];
  popupBtnClickedEvent: Event;
  actionBtnClickedEvent: Event;
  lastAppliedColDetails: any = null;
  loadPillArrBuildOnce: boolean;

  searchText: any;
  isMobileView: boolean = false
  orderStatusOptions=['All','Prepared']
  orderStatus=this.orderStatusOptions[0]
  selectedRowDataId:string
  tableCaptionButtonMenuClosed: boolean = true
  selectedRowId$ = this.store.select(selectedRowId)

  defaultDateTypeFilter: TableFilter;
  orderTableHeaderObjCount: number;
  domEvent:Event;

  ngOnInit(): void {
    // this.orderTableHeaderArray = this.translateTableHeaderArray(this.orderTableHeaderArray);
    this.store.select(getTableFilterDate).subscribe(filter =>{
      this.defaultDateTypeFilter = filter;
      this.dispatchUpdateDateRangeFieldDefaultFilterValue(this.defaultDateTypeFilter, {...this.orderTableHeaderObj});
    })
    this.store.select(isMobileViewSelector).subscribe((isMobileView) => {
      if (isMobileView !== null && isMobileView !== undefined) {
        this.isMobileView = isMobileView
       }
    })
  }
  onDropdownToggle(event: Event) {
    event.stopPropagation();
  }

  findKey(obj, targetKey) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key === targetKey) {
        return key;
      }
    }
    return null; // Key not found
  }


  dispatchUpdateDateRangeFieldDefaultFilterValue(filter, orderTableHeaderObj){
    const dateRangeTypeSelectedName = this.sessionStorageName + '_selectedDateRangeType';
    let filterDate = filter?.field
    const foundKey = this.findKey(orderTableHeaderObj, filterDate ?? '');
     if(!foundKey){
      filterDate = EXPECTED_DEL_DATE;
     }
    if(filterDate) {
      this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
        id: this.sessionStorageName,
        key: (sessionStorage.getItem(dateRangeTypeSelectedName) ?? filterDate)
      }));
    } else {
      this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
        id: this.sessionStorageName,
        key: (sessionStorage.getItem(dateRangeTypeSelectedName) ?? EXPECTED_DEL_DATE)
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let i = 0; i < Object.keys(changes).length; i++) {
      const propName = Object.keys(changes)[i];
      const change = changes[propName];
      if (propName === 'allAssets') {
        if (change.currentValue !== change.previousValue) {
          if (this.allAssets && this.allAssets.length > 0) {
            if (this.isSetFirstValAsDefaultVal) {
              if (this.selectionMode === 'single') {
                this.modernSelectedRows = this.allAssets[0];
              } else {
                this.modernSelectedRows.push(this.allAssets[0]);
              }
            }
          }
        }
      }
      if (propName === 'orderTableHeaderObj') {
        if (change.currentValue !== change.previousValue) {
          if (this.orderTableHeaderArray.length !== Object.entries(this.orderTableHeaderObj).length) {
            if(this.orderTableHeaderObjCount !== Object.entries(this.orderTableHeaderObj).length){
             this.dispatchUpdateDateRangeFieldDefaultFilterValue(this.defaultDateTypeFilter, {...this.orderTableHeaderObj});
            }
            this.orderTableHeaderObjCount = Object.entries(this.orderTableHeaderObj).length
            const tArr = this.buildOrderTableHeaderArray();
            this.orderTableHeaderArray =  tArr.filter(r => r !== null)
          }
          this.orderTableHeaderArray = this.translateTableHeaderArray(this.orderTableHeaderArray);
          this.setDatesForFilterTypeDateRange();
          this.buildSelectedFilterPillsArray(this.lazyLoadedObject?.filters);
          this.buildSeenOrderTableHeaderArray();
          this.seenOrderTableHeaderArray = this.translateTableHeaderArray(this.seenOrderTableHeaderArray);

        }
      }
      if (propName === 'resetModernSelectedRows') {
        if (change.currentValue !== change.previousValue) {
          this.modernSelectedRows = [];
          this.modernSelectedRowsChange(this.modernSelectedRows);
        }
      }
      if (propName === 'msgToShowInBtnPopup') {
        if (change.currentValue !== change.previousValue) {
          setTimeout(() => {
            if (this.popupBtnClickedEvent) {
              this.confirm(this.popupBtnClickedEvent);
            }
          }, 10);
        }
      }
      if (propName === 'msgToShowInActionClickedPopup') {
        if (change.currentValue !== change.previousValue) {
          setTimeout(() => {
            if (this.actionBtnClickedEvent) {
              this.actionBtnClickedConfirm(this.actionBtnClickedEvent);
            }
          }, 10);
        }
      }
      if (propName === 'buttonConfigObjArray') {
        if (change.currentValue !== change.previousValue) {
          const arr = [...this.buttonConfigObjArray]
          this.buttonConfigObjArray = sortBy(arr, ['sequence', 'id']);
        }
      }
    }
  }

  openSingleAction(rowData,config){
    const obj: SelectedAction = {
      label: config?.label,
      id: config?.id,
      data:  rowData
    }
    this.sendSelectedAction.emit(obj);
  }

  openActionMenu(event,rowData) {
    const availableMethods = rowData?.actionMethods;
    this.actionMenuItems = [{
      items: [{
        label: 'View Reciept',
        icon: 'pi pi-file',
        command: () => {
          this.sendCheckoutDataForPrint.emit(rowData);
        }
      },
        {
          label: 'Refund',
          icon: 'pi pi-money-bill',
          command: () => {
            this.sendRefundStart.emit(rowData);
          }
        },
        {
          label: 'Order Status',
          icon: 'pi pi-info-circle',
          command: () => {
            this.setOrderStatusUpdateModal(rowData['pickup']?.status, rowData['orderId'], rowData['_id'], rowData['paymentStatus'].label);
          }
        },
        {
          label: 'Payment',
          icon: 'pi pi-wallet',
          command: () => {
            this.setPaymentStatusModal(rowData['label'], rowData['orderId'], rowData['_id'], rowData['amount'], rowData['accountId'], rowData['paymentStatus'])
          }
        },
        {
          label: 'Print Sok Receipt',
          icon: 'pi pi-print',
          command: () => {
            this.sendPrintSokReceipt.emit({ id: rowData._id, orderNo: rowData.orderId });
          }
        },
        {
          label: 'Cancel',
          icon: 'pi pi-times',
          command: () => {
            this.sendCancelOrder.emit( rowData._id);
          }
        },
        {
          label: 'View QR Code',
          icon: 'pi pi-qrcode',
          command: () => {
            const obj: SelectedAction = {
              label: 'View QR Code',
              id: 'viewQrCode',
              data:  rowData
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Print QR Code',
          icon: 'pi pi-print',
          command: () => {
            const obj: SelectedAction = {
              label: 'Print QR Code',
              id: 'printQrCode',
              data:  rowData
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'View Batches',
          icon: 'pi pi-box',
          command: () => {
            const obj: SelectedAction = {
              label: 'View Batches',
              id: 'viewBatches',
              data:  rowData
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Split Requisition',
          icon: 'pi pi-sitemap',
          command: () => {
            const obj: SelectedAction = {
              label: 'Split Requisition',
              id: 'splitRequisition',
              data:  rowData
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
              this.deleteData(event,rowData);
            },
        },
        {
          label: 'Reorder',
          icon: 'pi pi-align-center',
          command: () => {
            this.reSuffleRoutePlanData.emit(rowData);
          }
        },
        {
          label: 'Complete transfer',
          icon: 'pi pi-arrow-right',
          command: () => {
            const obj: SelectedAction = {
              label: 'Complete transfer',
              id: 'transfer',
              data:  {...rowData,domEvent:this.domEvent}
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Fulfill Order',
          icon: 'pi pi-check',
          command: () => {
            const obj: SelectedAction = {
              label: 'Fulfill Order',
              id: 'fulfillOrder',
              data:  {...rowData,domEvent:this.domEvent}
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Customer Collaborations',
          icon: 'pi pi-images',
          command: () => {
            const obj: SelectedAction = {
              label: 'Collaborations',
              id: 'collaborations',
              data:  rowData
            }
            this.sendSelectedAction.emit(obj);
          }
        },
        {
          label: 'Sold Batches',
          icon: 'pi pi-money-bill',
          command: () => {
            const obj: SelectedAction = {
              label: 'Sold Batches',
              id: 'soldBatches',
              data:  {...rowData,domEvent:this.domEvent}
            }
            this.sendSelectedAction.emit(obj);
          }
        },
      ]
    }];
  
    this.actionMenuItems = this.actionMenuItems[0].items.filter((value) => {
      return availableMethods?.includes(value.label);
    });
  }


  setPaymentStatusModal(labelName, obj, objectId, amount, accountId, status?) {
    if (labelName === 'Not Paid' || labelName === 'N/A') {
      this.sendPaymentStatusModalChange.emit({ visibility: true, orderId: obj, id: objectId, amt: amount, accountId: accountId, status: (status ? status : null)});
    }
  }

  setShowTodaysPlacedOrder() {
    this.showAllOrdersToBeServed = !this.showAllOrdersToBeServed;
    this.sendDisplayPlacedOrder.emit(this.showAllOrdersToBeServed);
  }

  getFixedAmount(amountStr) {
    return (amountStr && amountStr.length > 0) ? Number(amountStr).toFixed(2) : '-';
  }


  getOrderStatusClass(status) {
    status = status.toLowerCase();
    switch (status) {
      case 'placed':
        return 'alert';
      case 'serviced':
        return 'success';
      case 'cancelled':
        return 'failure';
      case 'prepared':
        return 'info';
      case 'created':
        return 'initiated';
      default:
        return 'initiated';
    }
  }

  setOrderStatusUpdateModal(currStatus, orderId, objectId, paymentStatus, amount?) {
    if (currStatus.toLowerCase() === 'serviced' || paymentStatus === 'Not Paid') {
      return;
    }
    if (currStatus.toLowerCase() === 'cancelled') {
      return;
    }
    if (currStatus.toLowerCase() === 'discarded') {
      return;
    }
    let toBePrepared = false;
    if (currStatus.toLowerCase() === 'placed') {
      toBePrepared = true;
    }
    this.sendOrderStatusModalChange.emit({visibility: true, orderId: orderId, id: objectId, toBePrepared: toBePrepared, amt: amount});
  }

  setDatesForFilterTypeDateRange() {
    // let isDateRangeFieldPresent = false;


    const sessionStoredData = JSON.parse(sessionStorage.getItem(this.sessionStorageName));
    if (sessionStoredData && sessionStoredData['filters']) {
      /* if(this.loadPillArrBuildOnce){
        const dateRangeTypeSelectedName = this.sessionStorageName + '_selectedDateRangeType';
        this.loadPillArrBuildOnce = false;

    console.log(sessionStorage.getItem(dateRangeTypeSelectedName) ? sessionStorage.getItem(dateRangeTypeSelectedName) : 'no ')
        this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
          id: this.sessionStorageName,
          key: (sessionStorage.getItem(dateRangeTypeSelectedName) ? sessionStorage.getItem(dateRangeTypeSelectedName) : EXPECTED_DEL_DATE)
        }));
        sessionStorage.setItem(dateRangeTypeSelectedName, sessionStorage.getItem(dateRangeTypeSelectedName));
         return;
      } */

      /* forEach(sessionStoredData['filters'],(value, key) => {
        if (value[0] && value[0]['matchMode'] && (value[0]['matchMode'] === 'dateRange') && value[0]['value']  && value[0]['value'].length > 0) {
          this.selectedDateRangeField = key;
          if(this.loadPillArrBuildOnce){
            this.loadPillArrBuildOnce = false;
            this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
              id: this.sessionStorageName,
              key: key
            }));
            return false;
          }
        }
      }); */


    } /* else {
      if(this.r) {
        this.r = false;
            this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
              id: this.sessionStorageName,
              key: 'expectedDeliveryDateTimeDetails'
            }));
      }
    } */
  if(!this.checkIfStartDateAndEndDatePresent()){
   forEach(this.orderTableHeaderObj, (value, key) => {
      if (value && 'filterMenuType' in value) {
        const fType = value.filterMenuType;
        if (fType === 'dateRange') {
          if (this.lazyLoadedObject?.filters[key] && value.isDefaultFilter) {
            // isDateRangeFieldPresent = true;
            const filter = this.lazyLoadedObject.filters[key];
            if (!filter[0]['value']) {
              filter[0]['value'] = [];
            }
            if ('startDate' in value) {
              filter[0]['value'].push(new Date(value.startDate));
            }
            if ('endDate' in value) {
              filter[0]['value'].push(new Date(value.endDate));
            }
            this.lazyLoadedObject.filters[key] = filter;
          }
        }
      }
    });
  }
    // if (!isDateRangeFieldPresent) {
    //   if (this.lazyLoadedObject && this.lazyLoadedObject['filters']) {
    //     forEach(this.lazyLoadedObject['filters'], (value, _) => {
    //       if (value[0] && value[0]['matchMode'] && (value[0]['matchMode'] === 'dateRange')) {
    //         value[0]['value'] = [];
    //       }
    //     });
    //   }
    // }

  }

  checkIfStartDateAndEndDatePresent() {
    let isPresent = true;
    forEach(this.orderTableHeaderObj, (value, key) => {
      if (value && 'filterMenuType' in value) {
        const fType = value.filterMenuType && value.filterMenuType;
        if (fType === 'dateRange') {
          isPresent = Array.isArray(this.lazyLoadedObject?.['filters']?.[key])
            && this.lazyLoadedObject.filters[key][0]?.value?.length > 1
            && this.lazyLoadedObject.filters[key][0]?.value[0]
            && this.lazyLoadedObject.filters[key][0]?.value[1] !== null;
          if (isPresent) {
            return false;
          }
        }
      }
    });
    return isPresent;
  }


  getOrderedColumnSeenArray() {
    const orderedColumnSeenArray = [];
    for (let i = 0; i < this.orderTableHeaderArray.length; i++) {
      if (this.orderTableHeaderObj[this.orderTableHeaderArray[i]?.['field']]?.['isColumnSeen']) {
        orderedColumnSeenArray.push(this.orderTableHeaderArray[i]);
      }
    }
    return orderedColumnSeenArray;
  }

  isStoreSelected(store: KioskStore) {
    this.sendSelectedStore.emit(store);
  }

  customSort(event: SortEvent) {
    const fieldNameToSortBy = event['field'];
    const fieldIndexToSortBy = event['order'].toString();
    const sortObj = {
      'field': fieldNameToSortBy,
      'order': fieldIndexToSortBy
    };
    setTimeout(() => {
      if (this.isSortedBtnClicked) {
        this.sendSortByFieldObj.emit(sortObj);
      }
      this.isSortedBtnClicked = false;
    }, 100);
  }

  loadItems(event: LazyLoadEvent) {
    this.lazyLoadedObject = event;
    this.buildSelectedFilterPillsArray(this.lazyLoadedObject.filters);
    setTimeout(() => {
      if(this.checkIfStartDateAndEndDatePresent()) {
         this.sendLazyLoadedObject.emit(this.lazyLoadedObject);
      } else if(!this.isTableHasStateMng){
        this.setDatesForFilterTypeDateRange();
        this.buildSelectedFilterPillsArray(this.lazyLoadedObject?.filters);
      }
    }, 100);
    /* if (this.checkIfStartDateAndEndDatePresent()) {
      this.sendLazyLoadedObject.emit(this.lazyLoadedObject);
    } else if (this.defaultSelectedRow) {
      this.sendLazyLoadedObject.emit(this.lazyLoadedObject);
    } */
  }

  clickableDataIsClicked(data) {
    this.selectedRowDataId = data?._id
    this.store.dispatch(setSelectedIdToRowHighlight({selectedIdToHighlightRow : data?._id}))
    this.sendClickedRowDataToMainComp.emit(data);
  }

  modernSelectedRowsChange(orders) {
    const modifiedRows = cloneDeep(orders);
    remove(modifiedRows, function (order: any) {
      return order.isNotClickable;
    });
    if (modifiedRows?.length > 0) {
      this.tableCaptionButtonMenuClosed = false;
    } else {
      this.tableCaptionButtonMenuClosed = true;
    }
    this.sendModernSelectedRows.emit(modifiedRows);
  }

  deleteData(event,data){
    this.confirmationService.confirm({
      target: event.target,
      message: 'Do you really want to delete '+data?.label + ' ?',
      key: data?._id,
      accept: () => {
        this.removeData(event,data);
      },
      reject: () => {
        //reject action
      },
    });
  }
  removeData(event, data) {
    this.clickedRowData = data;
    this.actionBtnClickedEvent = event;
    data['popupEvent']=event;
    this.sendDataToRemove.emit(data);
  }

  buildOrderTableHeaderArray() {
    return Object.values(this.orderTableHeaderObj)?.map(o => {
      if(o['filterFieldName']){
        return {
          isColumnSeen: o['isColumnSeen'] ? o['isColumnSeen'] : false,
          field: o['filterFieldName'],
          header: o['columnDisplayName']
        };
      } else {
        return null;
      }
    });
  }

  buildSeenOrderTableHeaderArray() {
    this.seenOrderTableHeaderArray = [];
    const sessionColumnDropdownName = this.sessionStorageName + '_columnDropdown';
    if (sessionStorage.getItem(sessionColumnDropdownName)) {
      this.seenOrderTableHeaderArray = JSON.parse(sessionStorage.getItem(sessionColumnDropdownName));
      this.refreshOrderedSeenOrderTableHeaderArray('e');
      return;
    }

    forEach(this.orderTableHeaderArray, (value) => {
      if (value.isColumnSeen) {
        this.seenOrderTableHeaderArray.push(value);
      }
    });
    if (this.sessionStorageName) {
     // sessionStorage.setItem(sessionColumnDropdownName, (JSON.stringify(this.seenOrderTableHeaderArray)));
    }
  }

  refreshOrderedSeenOrderTableHeaderArray(_) {
    const sessionColumnDropdownName = this.sessionStorageName + '_columnDropdown';
    const dictOfSeenOrderTableHeaderArrayFieldNames = {};
    forEach(this.seenOrderTableHeaderArray, (_, index) => {
      dictOfSeenOrderTableHeaderArrayFieldNames[this.seenOrderTableHeaderArray[index]['field']] = true;
    });

    if (this.isTableHasStateMng == undefined || this.isTableHasStateMng) {
      forEach(this.orderTableHeaderArray, (_, index) => {
        if (this.orderTableHeaderArray[index]['field'] in dictOfSeenOrderTableHeaderArrayFieldNames) {
          if (this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']]?.['isColumnSeen'] !== true) {
            this.store.dispatch(updateOrderTableHeaderDataIsColumSeen({
              id: this.sessionStorageName,
              key: this.orderTableHeaderArray[index]['field'],
              data: this.orderTableHeaderArray[index]['field'] in dictOfSeenOrderTableHeaderArrayFieldNames
            }));
          }
        } else {
          if (this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']]?.['isColumnSeen'] !== false) {
            this.store.dispatch(updateOrderTableHeaderDataIsColumSeen({
              id: this.sessionStorageName,
              key: this.orderTableHeaderArray[index]['field'],
              data: this.orderTableHeaderArray[index]['field'] in dictOfSeenOrderTableHeaderArrayFieldNames
            }));
          }
        }
      });
    } else if (this.isTableHasStateMng === false) {
      forEach(this.orderTableHeaderArray, (_, index) => {
        if (this.orderTableHeaderArray[index]['field'] in dictOfSeenOrderTableHeaderArrayFieldNames) {


          this.orderTableHeaderObj={...this.orderTableHeaderObj,
              [this.orderTableHeaderArray[index]['field']]:{
                ...this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']],
                isColumnSeen:true
              }}
        } else {
          this.orderTableHeaderObj={...this.orderTableHeaderObj,
            [this.orderTableHeaderArray[index]['field']]:{
              ...this.orderTableHeaderObj[this.orderTableHeaderArray[index]['field']],
              isColumnSeen:false
            }}
        }
      });
    }
    if (this.sessionStorageName) {
      sessionStorage.setItem(sessionColumnDropdownName, (JSON.stringify(this.seenOrderTableHeaderArray)));
    }
  }

  buildSelectedFilterPillsArray(filters) {
    const filterPipe = new NamingConventionFilterPipe();
    this.selectedFilterPillsArray = [];
    forEach(filters, (value, key) => {
      const firstObj = value[0];
      switch (firstObj.matchMode) {
        case 'singleSelectFromOptions':
          const pillObj = {
            filterParameterValue: '',
            label: '',
            tooltip: '',
            key: '',
            removeAction: true
          };
          if (firstObj.value) {
            pillObj.filterParameterValue = firstObj.value.filterParameterValue ? firstObj.value.filterParameterValue : null;
            pillObj.label = firstObj.value.name ? firstObj.value.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          }
          break;
        case 'multiSelectFromOptions':
          forEach(firstObj.value, (obj) => {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: true
            };
            pillObj.filterParameterValue = obj.filterParameterValue ? obj.filterParameterValue : null;
            pillObj.label = obj.name ? obj.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          });
          break;
        case 'groupedMultiSelectFromOptions':
          forEach(firstObj.value, (obj) => {
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: true
            };
            pillObj.filterParameterValue = obj.filterParameterValue ? obj.filterParameterValue : null;
            pillObj.label = obj.name ? obj.name : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          });
          break;
        case 'dateRange':
          if (firstObj.value && firstObj.value.length > 1) {
            // const arrayLength = firstObj.value.length;
            const pillObj = {
              filterParameterValue: '',
              label: '',
              tooltip: '',
              key: '',
              removeAction: false
            };
            pillObj.filterParameterValue = firstObj.value[0] ? firstObj.value[0] : null;
            let locLabel = '';
            locLabel = `${moment(firstObj.value[0]).format('DD-MM-YY')} - ${moment(firstObj.value[1]).format('DD-MM-YY')}`;
            pillObj.label = locLabel ? locLabel : null;
            pillObj.key = key;
            pillObj.tooltip = filterPipe.transform(key);
            this.selectedFilterPillsArray.push(pillObj);
          }
          break;
        default:
          break;
      }
    });
  }

  pillRemoved(pill) {
    this.lazyLoadedObject.filters = (this.removeFilterManually(pill, this.lazyLoadedObject.filters));

    const sessionStoredData = JSON.parse(sessionStorage.getItem(this.sessionStorageName));
    if (sessionStoredData && sessionStoredData['filters']) {
      sessionStoredData['filters'] = (this.removeFilterManually(pill, sessionStoredData['filters']));
      sessionStorage.setItem(this.sessionStorageName, (JSON.stringify(sessionStoredData)));
    }

    this.sendLazyLoadedObject.emit(this.lazyLoadedObject);
  }

  removeFilterManually(pill, filters) {
    const selctedFieldToRemove = filters?.[pill?.key][0];
    switch (selctedFieldToRemove?.matchMode) {
      case 'singleSelectFromOptions':
        if (selctedFieldToRemove?.value?.filterParameterValue === pill?.filterParameterValue) {
          selctedFieldToRemove.value = null;
        }
        break;
      case 'multiSelectFromOptions':
        remove(selctedFieldToRemove.value, function (n: any) {
          return (n.filterParameterValue === pill.filterParameterValue);
        });
        break;
      case 'groupedMultiSelectFromOptions':
        remove(selctedFieldToRemove.value, function (n: any) {
          return (n.filterParameterValue === pill.filterParameterValue);
        });
        break;
      default:
        break;
    }

    return filters;
  }

  btnClicked(btnConfig) {
    this.clickedBtnConfig = btnConfig;
    this.sendClickedTableBtnToMainComp.emit(btnConfig);
  }

  confirm(event: Event) {
    if (event) {
      this.popupBtnClickedEvent = event;
      this.confirmationService.confirm({
        target: event.target,
        message: this.msgToShowInBtnPopup,
        icon: 'pi pi-exclamation-triangle',
        key: 'prime-table-popup',
        accept: () => {
          // confirm action
          this.sendPopupConfirmationEventToMainComp.emit(this.clickedBtnConfig);
        },
        reject: () => {
          // reject action
          this.sendPopupCancelEventToMainComp.emit(this.clickedBtnConfig);
        }
      });
    }
  }

  actionBtnClickedConfirm(event: Event) {
    if (event) {
      this.actionBtnClickedEvent = event;
      this.confirmationService.confirm({
        target: event.target,
        message: this.msgToShowInActionClickedPopup,
        icon: 'pi pi-exclamation-triangle',
        key: 'prime-table-popup',
        accept: () => {
          // confirm action
          this.sendPopupConfirmationActionEventToMainComp.emit(this.clickedRowData);
        },
        reject: () => {
          // this.sendPopupConfirmationRejectToMainComp.emit
          // reject action
          this.msgToShowInActionClickedPopup = '';
        }
      });
    }
  }

  setChartType(type) {
    this.dataViewType = type;
    this.sendDataViewTypeEventToMainComp.emit(this.dataViewType);
  }

//   -----input switch change handler -------
  inputSwitchChange(event, rowData) {
    const toggleData = {checked: event.checked, rowData: rowData};
    this.sendCheckedToggleButton.emit(toggleData);
  }

  sendTableChipRemoved(label, rowData, removedId, fieldName) {
    const event = {
      label,
      removedId,
      rowData,
      fieldName
    };
    this.sendTableChipRemoveData.emit(event);
  }

  cellEditorEvent(assetId, evt,field) {
    const event = {
      assetId: assetId?.['_id'],
      editedCellValue: evt
    };
    if(field==='parcelCharge'){
      this.sendEditedCellValue.emit({event,isParcelCharge:true});
    }
    else{
      this.sendEditedCellValue.emit(event);
    }
  }

  checkForWhichColumnFilterApplied(_, col) {
    const createdField = 'created';
    const expectedField =  CONSTANT.TABLE.EXPECTED_DELIVERY_DEFAULT_SORT_FIELD_NAME;
    this.lastAppliedColDetails = col;
    const fieldName = col['filterFieldName'];

    if (col.filterMenuType === 'dateRange' && !col?.isDefaultFilter) {


      const dateRangeTypeSelectedName = this.sessionStorageName + '_selectedDateRangeType';
      if (this.sessionStorageName) {
        sessionStorage.setItem(dateRangeTypeSelectedName, (col['filterFieldName']));
      }

      this.store.dispatch(updateDateRangeFieldDefaultFilterValue({
        id: this.sessionStorageName,
        key: col['filterFieldName']
      }));


      if (fieldName === createdField) {
        if(this.lazyLoadedObject?.filters?.[expectedField]?.[0]?.value) {
          this.lazyLoadedObject['filters'][expectedField][0].value = null;
          this.clearDateRangeValues(expectedField);
        }
      } else {
        if(this.lazyLoadedObject?.filters?.[createdField]?.[0]?.value) {
          this.lazyLoadedObject['filters'][createdField][0].value = null;
          this.clearDateRangeValues(createdField);
        }
      }

    }
  }

  clearDateRangeValues(fieldName) {
    const sessionStoredData = JSON.parse(sessionStorage.getItem(this.sessionStorageName));
    if (sessionStoredData && sessionStoredData['filters']) {
      forEach(sessionStoredData['filters'], function (_, key) {
        if (key === fieldName) {
          sessionStoredData['filters'][key][0]['value'] = [];
        }
      });
      this.lazyLoadedObject.filters = sessionStoredData['filters'];
      this.buildSelectedFilterPillsArray(this.lazyLoadedObject.filters);

      sessionStorage.setItem(this.sessionStorageName, (JSON.stringify(sessionStoredData)));
    }
  }

  onSearchEvent(event: Event){
    this.sendOnSearchValue.emit(event)
  }
  translateTableHeaderArray(arr){
    const translate = new TranslatePipe(this.translate, this.cd);
    let localArr = [...arr];
    localArr?.forEach((value,idx)=>{
      let localVal = {...value}
      let label = translate.transform(localVal['header'])
      localVal['header'] = label
      localArr[idx] = {...localVal}
    })
    return localArr
  }

  showFilters() {
    this.showFilterPills = !this.showFilterPills
   }

   emittFetchNewOrdersAction()
   {
    this.autoRefreshBtnConfig.newOrdersCount && this.emittGetNewOrdersAction.emit()
   }

   autoRefreshToggle(_){
    if (this.sessionStorageName) {
      const sessionARName = this.sessionStorageName + '_autoRefresh'
      sessionStorage.setItem(sessionARName, (this.autoRefreshBtnConfig.checked).toString());
    }
    if(this.autoRefreshBtnConfig.newOrdersCount && this.autoRefreshBtnConfig.checked){
      this.emittGetNewOrdersAction.emit()
    }
   }
}

export interface TableBtn {
  label?: string;
  id: string;
  pIcon?: string;
  type: string;
  dropDownBtnValues?: Array<DropdownTableBtn>;
  toolTipData?: string;
  disabled?: boolean;
  sequence: number;
  showPopup?: boolean;
  removeButton?: boolean;
  stateOptions?: IStateOption[];
  action?: string
}

export interface DropdownTableBtn {
  label: string;
  icon: string;
  id?: string;
  command: any;
  action?: string
}

export interface IStateOption {
  label: string;
  value: string;
}

export interface SelectedAction {
  id: string;
  label: string;
  data: any;
}

export interface IAutoRefreshOrdersBtnConfig {
  checked?: boolean;
  showRefreshIcon?: boolean;
  showEnableBtn?: boolean;
  newOrdersCount: number;
  showAlertNotificationBtn?: boolean
  showRefreshUI?: boolean;
}
