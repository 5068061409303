import { createReducer, on } from "@ngrx/store"
import { orderBy } from "lodash";
import { dashboardAction } from "."
import { IKioskDashboard, ILmdDashboard, IMmdDashboard, IMmdDeliveredAnalyticsData, IO2DOverviewAnalytics, IO2DOverviewAnalyticsData, IO2dDashboard, IWarehouseDashboard, defaultBarChartData } from "../interface"
import { boxStatusGraph, buildChartDataForGraph, buildChartDataForGraphIfDashboardFalse, buildChartDataForRoutePlanTemp, plotTemperatureAndHumidityGraph, getCountsByOrderSource, getOrdersByDeliveryStatus, getOrdersCountInQueue, getOrderTemperatureDetails, getQueueData, getRiderWisePerformance, getRoutePlansAssignedStatusGraph, lmdTatOfOrderInPercentage, mmdTemperatureDetails, removeArrayOfGraphTypePills, searchInArrayByField, sortArrayByField, updateSpecificGraphOnGpsData, getTotalDistanceTravelledForMultipleCars, plotMMDDistanceTravelledByTimeAndSeriesType, buildTempertureAndBuildForLMDGraphs, changeDMSVehicleDistanceTravelledGraphType } from "./dashboard.helper.service"
import { CONSTANT } from "../../../config/constant";
// const dash : dashboardService;
const GRAPH_KEYS = CONSTANT.GRAPH_KEYS;
const ANALYTICS = 'analytics'
export interface DashboardState {
    o2d : IO2dDashboard;
    mmd :IMmdDashboard;
    lmd :ILmdDashboard;
    warehouseDashboard: IWarehouseDashboard;
    kiosk:IKioskDashboard;
    showLoader : boolean;
    mmdDeliveryAnalytics: IMmdDeliveredAnalyticsData;
    o2dOverviewAnalytics: IO2DOverviewAnalytics;
    o2dOverviewAnalyticsPaginatedData: IO2DOverviewAnalyticsData[];
    o2dOverviewAnalyticsDataCount: number;

    dmsVehicleAnalytics:any;
}
export const initialState : DashboardState ={
     o2d : {
      orderStateData : {
          total: 0,
          delivered: 0,
          assigned: 0,
          cancelled: 0,
          deliveryPartnerReturnedOrder: 0
        },
      o2dGraphs : null,
     },
     mmd : {
      orderStateData : {
        total: 0,
        totalOrder : 0,
        delivered: 0,
        assigned: 0,
        cancelled: 0,
        deliveryPartnerReturnedOrder: 0
      },
      assignedRoutePlansData:[],
    mmdGraphs : null
     },
     lmd : {
      orderStateData : {
        total: 0,
        delivered: 0,
        assigned: 0,
        cancelled: 0,
        deliveryPartnerReturnedOrder: 0
      },
    lmdGraphs:null
     },
     warehouseDashboard:{
       zoneList : [],
       warehouseDashboardGraph:[],
       warehouseGraphs : null
     },
     kiosk : {
      countCards : {
        total: 0,
        paid: 0,
        discarded: 0,
        refunded: 0,
       totalAmount: 0,
       netAmount: 0,
       taxAmount: 0,
      },
     kioskGraphs : null,
     },
     showLoader : false,
     mmdDeliveryAnalytics: {
      tableData : [],
      pagination: null,
      tripCount:[],
      deliveryCount: []
     },
     o2dOverviewAnalytics: {
       data: [],
       headers:[],
       orderCount: []
     },
     o2dOverviewAnalyticsPaginatedData: [],
     o2dOverviewAnalyticsDataCount: 0,



     dmsVehicleAnalytics:{  
      graphAnalyticsData: {
        graph: {
          carsSelected: [],
          dateRange: {
            start: new Date(),
            end: new Date()
          },
          distanceTravelled: [],
          distanceTravelled1: [],
          displayedColumns: ['regNo', 'distanceInString']
        }
      },
    selectedVehiclesSensorData: {},
    groupByRegNumber:{},
     carDistanceMovedStats : {
      distanceMoved: {
        chartData: {},
        show: true,
        showLoader: false,
        title:'',
        updateValue: "this.updateValue",
        graphTypeChangedRandomNumber: 0
      },
      pills: {
        selected: 'hour'
      },
      graphType: 'bar'
    },
     boxStatusGraph : {
       temperature: {
         chartData: {},
         show: true,
         showLoader: true,
         title: "Temperature",
         updateValue: "this.updateValue",
         graphType: 'line'
       },
       humidity: {
         chartData: {},
         show: true,
         showLoader: true,
         title: "Humidity",
         updateValue: "this.updateValue",
         graphType: 'line'
       }
     }}
}
export const dashboardReducer = createReducer(
    initialState,
    on(dashboardAction.getO2dOrderStatsDataSuccess,(state,{res,providedOrderStatus})=>{
        let temp = structuredClone(state.o2d.orderStateData)
        temp[providedOrderStatus]= res['count']
            return {...state,
              o2d: {
                ...state.o2d,
                orderStateData : temp
              }
            }

    }),
    on(dashboardAction.O2dOrderStatsDataFailureMethod, (state)=>{
        return {
            ...state
        }
    }),
    on(dashboardAction.getOrderByTimeSlotSuccess,(state,{res,title,graphSettings,graphKey})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : {...state.o2d.o2dGraphs ,
            [graphKey] : {...getCountsByOrderSource(res,title,'slot','orderSource',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
          }
        }
      }
    }),
    on(dashboardAction.getOrderByAssignStatusSuccess,(state,{res,title,graphSettings,graphKey})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            [graphKey] :  {...getCountsByOrderSource(res,title,'slot','assignedStatus',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
          }
        }
      }
    }),
    on(dashboardAction.getOrderByDeliveryStatusSuccess,(state,{res,title,graphSettings,graphKey})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            [graphKey] : {... getCountsByOrderSource(res,title,'slot','deliveryStatus',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS} 
          }
        }
      }
    }),
    on(dashboardAction.getOrderByQueueSuccess,(state,{res})=>{
      let obj = getQueueData(res);
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            'ordersInQueue_dash' : {...state.o2d.o2dGraphs?.ordersInQueue_dash, ...res ? {...getOrdersCountInQueue(obj?.ordersCountsInQueue,'Order in Queue',null,null)} : null},
            'agentsInQueue_dash' : {...state.o2d.o2dGraphs?.agentsInQueue_dash, ...res ? {...getOrdersCountInQueue(obj?.agentsInQueue,'Delivery Persons in Queue',null,null)} : null},
          }
        }
      }
    }),
    on(dashboardAction.getCountInQueueSuccess,(state,{res})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            'ordersInQueue_dash' : {...state.o2d.o2dGraphs?.ordersInQueue_dash , count:res && res['count'] ? res['count'] : 0 }
          }
        }
      }
    }),
    on(dashboardAction.getDeliveryInCountSuccess,(state,{res})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            'agentsInQueue_dash' : {...state.o2d.o2dGraphs?.agentsInQueue_dash , count:res && res['count'] ? res['count'] : 0 }
          }

        }
      }
    }),
    on(dashboardAction.getRiderWisePerformanceSuccess,(state,{res,title})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            'riderWisePerformance_dash' : res ? {...getRiderWisePerformance({...res},title,null)} : null
          }
        }
      }
    }),
    on(dashboardAction.getO2dTatOfOrderSuccess,(state,{res,title,graphSettings,graphKey})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            [graphKey] : res ? {...getCountsByOrderSource(res,title,'barName','status',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS} : null
          }
        }
      }
    }),
    on(dashboardAction.getDeliveryTemperatureSuccess,(state,{res,title,graphSettings})=>{
      return {
        ...state,
        o2d: {
          ...state.o2d,
          o2dGraphs : { ...state.o2d.o2dGraphs ,
            [GRAPH_KEYS.O2D.DELIVERY_TEMPERATURE_DASHBOARD] : res ? {...getOrderTemperatureDetails(res,title,graphSettings.graphType)} : null
          }
        }
      }
    }),
    on(dashboardAction.resetO2dDashboardState,(state)=>{
      return {
        ...state,
        o2d :  {
          orderStateData : {
          total: null,
          delivered: null,
          assigned: null,
          cancelled: null,
          deliveryPartnerReturnedOrder: null
        },
        o2dGraphs : null,
        }
     }
    }),
    on(dashboardAction.getMmdOrderStatsDataSuccess,(state,{res})=>{
      let temp = structuredClone(state.mmd.orderStateData)
      let total=0
      const statuses=['pending', 'returning', 'completed']
      if(res.length){
        res?.forEach(status => {
          const key= status.status+'_count'
          if (statuses.includes(status.status)) {
            total += status.count;
            temp[key] = status.count || 0;
          }else{
            temp[key] = 0;
          }
        })
        temp['total_count'] = total;
      }else{
        statuses.forEach(s=>{
          const key= s+'_count'
          temp[key] = 0;
          temp['total_count'] = 0;
        })
      }

          return {...state,
            mmd: {
              ...state.mmd,
              orderStateData : temp
            }
          }
  }),
  on(dashboardAction.getMmdDashboardByTimeSlotSuccess,(state,{res,title,action})=>{
    if(action?.isDashBoard === 'false'){
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          'orderSource_dash' : {...buildChartDataForGraphIfDashboardFalse(res,title,{graphType:'bar'},null,null)}
        }
      } }
     } else{
      return {
        ...state,
        mmd: {
          ...state.mmd,
          mmdGraphs : {...state.mmd.mmdGraphs ,
            'orderSource_dash' : {...getCountsByOrderSource(res,title,'slot','orderSource',null)}
          }
        }
      }
    }
  }),


  on(dashboardAction.getRoutePlansTempDataSuccess,(state,{res,titles,graphSettings})=>{
    let formattedData= {}
    res?.forEach(rplan=>{
      const modifiedData={       
      ...rplan, 
      "count": (formattedData?.[rplan.status]?.length + 1)  || 1,
      "deliveryStatus": rplan.status==='pending' ? 'progress' : rplan.status,
    }
      formattedData[rplan.status]=[...(formattedData[rplan.status] || []),modifiedData]
    });

    const uniqueKeyStatus = GRAPH_KEYS.MMD.ROUTE_PLANS_STATUS_DASHBOARD
    const uniqueKeyTemp = GRAPH_KEYS.MMD.IN_PROGRESS_ROUTE_PLANS_DASHBOARD

    let result = getOrdersByDeliveryStatus(formattedData,titles[uniqueKeyStatus],graphSettings[uniqueKeyStatus],'day','timeZoneDate','deliveryStatus',formattedData);

    result.counts.chartData.legend = (result?.counts?.chartData?.legend || []).map(status => status === 'pending' ? 'progress' : status)

    result.counts.chartData.xAxis = result.counts.chartData.legend;
    result.counts.chartData.series.forEach((val,index) => {
         let array = []
         array[index]=val.data[0];
         val.data = array
    })
  
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          [uniqueKeyTemp] : {...buildChartDataForRoutePlanTemp(res,titles[uniqueKeyTemp],graphSettings[uniqueKeyTemp].graphType),data:res,uniqueKey:uniqueKeyTemp,isAnalytics:uniqueKeyTemp.split('_')[2]===ANALYTICS},
          [uniqueKeyStatus]: {...result,data:res,uniqueKey:uniqueKeyStatus}
        },
        assignedRoutePlansData : res
      } 
    }
  }),




  on(dashboardAction.getRoutePlansAssignedStatusDataSuccess,(state,{res,title,graphSettings})=>{
    
    let result = getRoutePlansAssignedStatusGraph(
      {
        assigned:state?.mmd?.assignedRoutePlansData || [],
        all:res || []
      },title,graphSettings,undefined,'timeZoneDate','deliveryStatus',true);

    result.counts.chartData.xAxis = [...result?.counts?.chartData?.legend];
    result.counts.chartData.series.forEach((val,index) => {
         let array = []
         array[index]=val.data[0];
         val.data = array
    })
    const uniqueKey = GRAPH_KEYS.MMD.ROUTE_PLANS_ASSIGNED_STATUS_DASHBOARD
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          [uniqueKey]: {...result, data:res,uniqueKey,isAnalytics:uniqueKey.split('_')[2]===ANALYTICS}
        }
      } 
    }
  }),


  on(dashboardAction.getMmdDashboardByDeliveryStatusSuccess,(state,{res,title,action})=>{
    if(action?.isDashBoard === 'true'){
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          'orderDeliveryStatus_dash' : {...getCountsByOrderSource(res,title,'slot','deliveryStatus',null)}
        }
      }
    }}else{
      return {
        ...state,
        mmd: {
          ...state.mmd,
          mmdGraphs : {...state.mmd.mmdGraphs ,
            'orderDeliveryStatus_dash' : {...buildChartDataForGraphIfDashboardFalse(res,title,{graphType:'bar'},null,null)}
          }
        }
      }
    }
  }),
  on(dashboardAction.getMmdOrderTotalOrdeGraphCountSuccess,(state,{res,title,action,graphSettings,graphKey})=>{
    if(action?.isDashBoard === 'true'){
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          [graphKey] : {...getCountsByOrderSource(res,title,'slot','status',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
        }
      }
    }}else{
      return {
        ...state,
        mmd: {
          ...state.mmd,
          mmdGraphs : {...state.mmd.mmdGraphs ,
            [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,null,null),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
          }
        }
      }
    }
  }),
  on(dashboardAction.getMmdByTemperatureSuccess,(state,{res,title})=>{
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          'boxTemperature_dash' : {...mmdTemperatureDetails(res,title)}
        }
      }
    }
  }),
  on(dashboardAction.changeShowLoaderValue,(state,{show})=>{
    return {
      ...state,
      showLoader : show
      }
  }),
  on(dashboardAction.getLmdOrderStatsDataSuccess,(state,{res,providedOrderStatus})=>{
    let temp = structuredClone(state.lmd.orderStateData)
    temp[providedOrderStatus]= res['count'];
        return {...state,
          lmd: {
            ...state.lmd,
            orderStateData : temp
          }
        }
 }), on(dashboardAction.getLmdDashboardByTimeSlotSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : {...state.lmd.lmdGraphs ,
        [graphKey] : {...getCountsByOrderSource(res,title,'slot','orderSource',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
 }),
 on(dashboardAction.getLmdDashboardByAgentAssignedStatusSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [graphKey] :  {...getCountsByOrderSource(res,title,'slot','assignedStatus',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getLmdDashboardByDeliveryStatusSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : {...state.lmd.lmdGraphs ,
        [graphKey] : {...getCountsByOrderSource(res,title,'slot','deliveryStatus',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getLmdOrderByQueueSuccess,(state,{res,deliveryPersonGraphSettings,orderGraphSettings})=>{
  let obj = getQueueData(res);
  const graphKey1 =GRAPH_KEYS.LMD.ORDER_IN_QUEUE_DASHBOARD
  const graphKey2 =GRAPH_KEYS.LMD.DELIVERY_PERSONS_IN_QUEUE_DASHBOARD
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [graphKey1] : {...(state.lmd?.lmdGraphs?.[graphKey1] || {}), ...res ? {...getOrdersCountInQueue(obj?.ordersCountsInQueue,'Order in Queue',null,orderGraphSettings)} : null,isAnalytics:graphKey1.split('_')[2]===ANALYTICS},
        [graphKey2] : {...state.lmd?.lmdGraphs?.[graphKey2], ...res ? {...getOrdersCountInQueue(obj?.agentsInQueue,'Delivery Persons in Queue',null,deliveryPersonGraphSettings)} : null,isAnalytics:graphKey2.split('_')[2]===ANALYTICS},
      }
    }
  }
}),
on(dashboardAction.getLmdCountInQueueSuccess,(state,{res}):any=>{
  const graphKey =GRAPH_KEYS.LMD.ORDER_IN_QUEUE_DASHBOARD
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [graphKey] : {
          ...state.lmd.lmdGraphs?.[graphKey] , 
          isAnalytics:graphKey.split('_')[2]===ANALYTICS,
          count:res?.['count'] || 0
         }
      }
    }
  }
}),
on(dashboardAction.getLmdDeliveryInCountSuccess,(state,{res})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [GRAPH_KEYS.LMD.DELIVERY_PERSONS_IN_QUEUE_DASHBOARD] : {...state.lmd.lmdGraphs?.[GRAPH_KEYS.LMD.DELIVERY_PERSONS_IN_QUEUE_DASHBOARD] , count:res && res['count'] ? res['count'] : 0 }
      }

    }
  }
}),
on(dashboardAction.getLmdRiderWisePerformanceSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [graphKey] : res ? {...getRiderWisePerformance({...res},title,null,graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true} : null
      }
    }
  }
}),
on(dashboardAction.getLmdTatOfOrderSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
        [graphKey] : res ? {...getCountsByOrderSource(res,title,'barName','status',graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true} : null
      }
    }
  }
}),
on(dashboardAction.getLmdByTemperatureSuccess,(state,{res,title,graphSettings,graphKey})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : {...state.lmd.lmdGraphs ,
        [graphKey] : {...mmdTemperatureDetails(res,title,graphSettings.graphType),isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getLmdTatOfOrderInPercentageSuccess,(state,{res,title,graphSettings})=>{
  return {
    ...state,
    lmd: {
      ...state.lmd,
      lmdGraphs : { ...state.lmd.lmdGraphs ,
       [GRAPH_KEYS.LMD.TAT_OF_ORDER_IN_PERCENTAGE_DASHBOARD] : res ? {...lmdTatOfOrderInPercentage(res,title,graphSettings.graphType)} : null
      }
    }
  }
}),
//warehouse dashboard 
on(dashboardAction.getAllZoneForWarehouseSuccess,(state,{res,ObjGrapg})=>{
  return {
    ...state,
    warehouseDashboard: { ...state.warehouseDashboard,
      'zoneList' : [...res ?? [] ],
      'warehouseDashboardGraph' : [ObjGrapg],
    }
  }
}),
on(dashboardAction.getWarehouseDashboardAllMonitoredCitySuccess,(state,{locationList})=>{
  return {
    ...state,
    warehouseDashboard: {
      ...state.warehouseDashboard,
      'locationList' : locationList,
      'selectedLocation' : locationList[0],
    }
  }
}),
on(dashboardAction.updateWarehouseSelectedLocationInState,(state,{selectedLocation})=>{
  return {
    ...state,
    warehouseDashboard: {
      ...state.warehouseDashboard,
      'selectedLocation' : selectedLocation ?? null,
    }
  }
}),
on(dashboardAction.updateWarehouseZoneEchartGraphWebSocketSuccess,(state, {gpsData,}) => {
  if(gpsData){
    let graphData = updateSpecificGraphOnGpsData(gpsData,state?.warehouseDashboard?.warehouseDashboardGraph[0]);
    if(graphData) {
      return {
        ...state,
        warehouseDashboard : {
          ...state.warehouseDashboard,
          warehouseDashboardGraph : [graphData]
        }
      }
    } else{
      return {
        ...state
      }
    }
  }else{
    return {
      ...state
    }
  }
  }),
   on(dashboardAction.getAllDashboardCitiesSuccess,(state, {res}) => {
    let cityList = orderBy(res, ["city"], ["asc"]);
            if (res.length > 0) {
              let cityObject = { city: "All", _id: null };
              cityList.unshift(cityObject);
            }
    return {
      ...state,
      warehouseDashboard : {
        ...state.warehouseDashboard, 
          'city' : cityList,
          'selectedCity' : cityList[0],
        
      }
    }
  }),
  //warehouse analytics 
  on(dashboardAction.getWarehouseAnalyticsGraphDataSuccess,(state,{res})=>{
    return {
      ...state,
      warehouseDashboard: {
        ...state.warehouseDashboard,
        warehouseGraphs : {...state.warehouseDashboard.warehouseGraphs ,
          'boxStatusGraph_analytics' : res
        }
      }
    }
  }),
  on(dashboardAction.resetWarehouseAnalyticsGraphData,(state)=>{
    return {
      ...state,
      warehouseDashboard: {
        ...state.warehouseDashboard,
        warehouseGraphs : {...state.warehouseDashboard.warehouseGraphs ,
          'boxStatusGraph_analytics' : {...boxStatusGraph,
            temperature : {...boxStatusGraph.temperature , 
              chartData : {...defaultBarChartData , title : 'Temperature'}
            },
            humidity : {...boxStatusGraph.humidity,
               chartData : {...defaultBarChartData , title : 'Humidity'}
            }
          }
        }
      }
    }
  }),
//kiosk dashboard 
on(dashboardAction.getKioskTotalCountCardDataSuccess,(state,{res,providedOrderStatus})=>{
  let temp = structuredClone(state.kiosk.countCards)
  temp[providedOrderStatus]= res['amount']>=0 ? res['amount'] : res['count'];
      return {...state,
        kiosk: {
          ...state.kiosk,
          countCards : temp
        }
      }
}),
on(dashboardAction.getKioskByTotalOrderSuccess,(state,{res,title,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getKioskByPaidOrderSuccess,(state,{res,title,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getKioskByDiscardedOrderSuccess,(state,{res,title,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getKioskByRefundedOrderSuccess,(state,{res,title,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getKioskByTotalSalesSuccess,(state,{res,title,fieldName,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,fieldName),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.getKioskByTopProductsSuccess,(state,{res,title,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,''),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
//mmd - analytics Graph
on(dashboardAction.getCountByOrderSourceMmdAnalyticsSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    mmd: {
      ...state.mmd,
      mmdGraphs : {...state.mmd.mmdGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
   }
  }),
  on(dashboardAction.getMmdAnalyticsByDeliveryStatusSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          [graphKey] :{...getOrdersByDeliveryStatus(res,title,graphSettings,pillValue,'timeZoneDate','deliveryStatus',null),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
        }
      }
    }
  }),
  on(dashboardAction.getMmdAnalyticsDeliveryTemperatureRequired,(state,{res,title,graphKey})=>{
    return {
      ...state,
      mmd: {
        ...state.mmd,
        mmdGraphs : {...state.mmd.mmdGraphs ,
          [graphKey] : {...mmdTemperatureDetails(res,title,'scatter','day'),uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
        }
      }
    }
  }),
  //  lmd - analytics graph
  on(dashboardAction.getCountByOrderSourceLmdAnalyticsSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
    return {
      ...state,
      lmd: {
        ...state.lmd,
        lmdGraphs : {...state.lmd.lmdGraphs ,
          [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
        }
      }
     }
    }),
    on(dashboardAction.getCountByAgentAssignmentLmdAnalyticsSuccess,(state,{res,title,pillValue,idToRemove,graphSettings,graphKey})=>{
      let response = getOrdersByDeliveryStatus(res,title,graphSettings,pillValue,'timeZoneDate','deliveryStatus',null);
      let finalRes = removeArrayOfGraphTypePills(idToRemove,response)
      return {
        ...state,
        lmd: {
          ...state.lmd,
          lmdGraphs : { ...state.lmd.lmdGraphs ,
            [graphKey] :  {...finalRes,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
          }
        }
       }
      }),
      on(dashboardAction.getLmdAnalyticsByDeliveryStatussSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
        return {
          ...state,
          lmd: {
            ...state.lmd,
            lmdGraphs : {...state.lmd.lmdGraphs ,
              [graphKey] :{...getOrdersByDeliveryStatus(res,title,graphSettings,pillValue,'timeZoneDate','deliveryStatus',null),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
            }
          }
        }
      }),
      on(dashboardAction.getLmdAnalyticsByQueueSuccess,(state,{res,pillValue,deliveryPersonGraphSettings,orderGraphSettings})=>{
        let obj = getQueueData(res);
        return {
          ...state,
          lmd: {
            ...state.lmd,
            lmdGraphs : { ...state.lmd.lmdGraphs ,
              [GRAPH_KEYS.LMD.ORDER_IN_QUEUE_ANALYTICS] : {...state.lmd.lmdGraphs?.ordersInQueue_analytics, ...res ? {...getOrdersCountInQueue(obj?.ordersCountsInQueue,'Order in Queue',pillValue,orderGraphSettings)} : null},
              [GRAPH_KEYS.LMD.DELIVERY_PERSONS_IN_QUEUE_ANALYTICS] : {...state.lmd.lmdGraphs?.agentsInQueue_analytics, ...res ? {...getOrdersCountInQueue(obj?.agentsInQueue,'Delivery Persons in Queue',pillValue,deliveryPersonGraphSettings)} : null},
            }
          }
        }
      }),
      on(dashboardAction.getLmdRAnalyticsRiderWisePerformanceSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
        return {
          ...state,
          lmd: {
            ...state.lmd,
            lmdGraphs : { ...state.lmd.lmdGraphs ,
              [graphKey] : res ? {...getRiderWisePerformance({...res},title,pillValue,graphSettings),isAnalytics:graphKey.split('_')[2]===ANALYTICS} : null
            }
          }
        }
      }),
      on(dashboardAction.getLmdRAnalyticsOrderByAgentSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
        return {
          ...state,
          lmd: {
            ...state.lmd,
            lmdGraphs : {...state.lmd.lmdGraphs ,
              [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
            }
          }
         }
      }),
      on(dashboardAction.getLmdAnalyticsTatOfOrderSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
        return {
          ...state,
          lmd: {
            ...state.lmd,
            lmdGraphs : {...state.lmd.lmdGraphs ,
              [graphKey] : {...getOrdersByDeliveryStatus(res,title,graphSettings,pillValue,'barName','status',null),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
            }
          }
         }
     }),
     on(dashboardAction.getLmdRAnalyticsDeliveryTemperatureSuccess,(state,{res,title,pillValue,graphKey})=>{
      return {
        ...state,
        lmd: {
          ...state.lmd,
          lmdGraphs : {...state.lmd.lmdGraphs ,
            [graphKey] : {...mmdTemperatureDetails(res,title,'bar',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
          }
        }
       }
   }),


// o2d analytics ************
on(dashboardAction.getO2dOrderAnalyticsByLogicticProviderSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByCitySuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByOrderTypeSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByLocationSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByOrderValueByLPSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs ,
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'sumValue',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByOrderValueByCitySuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs , 
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'sumValue',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getO2dOrderAnalyticsByTatOfOrderSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    o2d: {
      ...state.o2d,
      o2dGraphs : {...state.o2d.o2dGraphs , 
        [graphKey] : {...buildChartDataForGraphIfDashboardFalse(res,title,graphSettings,'count',pillValue),isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
//kiosk analytics 

on(dashboardAction.getKioskAnalyticsTotalOrdersSuccess,(state,{res,title,pillValue,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs , 
       [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,'',pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskAnalyticsPaidOrderSuccess,(state,{res,title,pillValue,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs , 
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,'',pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskAnalyticsDiscardedOrderSuccess,(state,{res,title,pillValue,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs, 
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,'',pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskAnalyticsRefundedOrderSuccess,(state,{res,title,pillValue,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs , 
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,'',pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskAnalyticsByTotalSalesSuccess,(state,{res,title,fieldName,pillValue,graphKey,graphSettings})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,fieldName,pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskAnalyticsByTopProductSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(res,title,graphSettings,'count',pillValue),data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS}
      }
    }
  }
}),
on(dashboardAction.getKioskSalesByDineTypesSuccess,(state,{res,title,pillValue,graphSettings,graphKey})=>{
  const modResponse = {}
  for(let dineType in res || {}){
    if(res[dineType].length){
      modResponse[dineType] = res[dineType]
    }
  }
  return {
    ...state,
    kiosk: {
      ...state.kiosk,
      kioskGraphs : {...state.kiosk.kioskGraphs ,
        [graphKey] : {...buildChartDataForGraph(modResponse,title,graphSettings, 'amount',pillValue),
        data:res,uniqueKey:graphKey,isAnalytics:graphKey.split('_')[2]===ANALYTICS,hideCount:true}
      }
    }
  }
}),
on(dashboardAction.resetGraphTypePillValueSuccess,(state,{title,obj,moduleName})=>{
  return {
    ...state,
    [moduleName]: {
      ...state?.[moduleName],
      [moduleName + 'Graphs'] : {...state?.[moduleName]?.[moduleName + 'Graphs'] ,
        [title] : obj
      }
    }
  }
}),
  on(dashboardAction.getMmdDeliveredGroupAnalyticsSucces,(state,{res})=>{
    return {
      ...state,
      mmdDeliveryAnalytics: res
    }
  }),
  on(dashboardAction.getO2dOverviewAnalyticsSucces,(state,{res})=>{
    return {
      ...state,
      o2dOverviewAnalytics: res
    }
  }),
  on(dashboardAction.getO2dOverviewAnalyticsDataWithSkipLimit,(state,{skip, limit, sortOrder, sortField, searchedText})=>{
    let data = [];
    let count = 0;
    if(searchedText){
      data = searchInArrayByField([...state.o2dOverviewAnalytics.data], searchedText, 'name')
      data = sortArrayByField([...data], sortField,sortOrder)
    } else {
      data = sortArrayByField([...state.o2dOverviewAnalytics.data], sortField,sortOrder)
    }
    count = data?.length;
    const result = data.slice(skip, skip + limit);
    return {
      ...state,
      o2dOverviewAnalyticsPaginatedData: result,
      o2dOverviewAnalyticsDataCount: count
    }
  }),

  on(dashboardAction.getTemperatureAndHumidityGraphSuccess,(state,{dateDisplayFormate,res, selectedVehicles,selectedVIds})=>{

    const  { boxStatusGraph,selectedVehiclesSensorData} =plotTemperatureAndHumidityGraph(
      res,
      dateDisplayFormate, 
      selectedVehicles,
      selectedVIds,
      state.dmsVehicleAnalytics.selectedVehiclesSensorData,
      state.dmsVehicleAnalytics.boxStatusGraph
    )
    return {
      ...state,
      dmsVehicleAnalytics:{
      ...state.dmsVehicleAnalytics ,boxStatusGraph,selectedVehiclesSensorData
      }
    }
  }),
  on(dashboardAction.getDMSVehicleDistanceTravelledGraphSuccess,(state,{distanceChartData})=>{
    let groupByRegNumberMod;
    let graphAnalyticsDataMod;

    distanceChartData.forEach(data=>{
      const {groupByRegNumber,graphAnalyticsData} = getTotalDistanceTravelledForMultipleCars(data,state.dmsVehicleAnalytics.groupByRegNumber,state.dmsVehicleAnalytics.graphAnalyticsData)
      graphAnalyticsDataMod = graphAnalyticsData
      groupByRegNumberMod = groupByRegNumber
    })
    
    return {
      ...state,
      dmsVehicleAnalytics:{
      ...state.dmsVehicleAnalytics ,groupByRegNumber:groupByRegNumberMod,graphAnalycarDistanceMovedStatsticsData:graphAnalyticsDataMod
      }
    }
  }),

  on(dashboardAction.getDMSDistanceTravelledByTimeAndSeriesTypeSuccess,(state,{distanceChartData,title,series,graphType})=>{
   
    const carDistanceMovedStats = plotMMDDistanceTravelledByTimeAndSeriesType(distanceChartData,state?.dmsVehicleAnalytics?.carDistanceMovedStats,title,series,graphType)
    
    return {
      ...state,
      dmsVehicleAnalytics:{
      ...state.dmsVehicleAnalytics,
      carDistanceMovedStats:{...(state?.dmsVehicleAnalytics?.carDistanceMovedStats || {}),...carDistanceMovedStats}
      }
    }
  }),

  on(dashboardAction.getLMDTemperatureAndHumidityGraphSuccess,(state,{dateDisplayFormate,res})=>{
    return {
      ...state,
      dmsVehicleAnalytics:{
      ...state.dmsVehicleAnalytics ,boxStatusGraph : buildTempertureAndBuildForLMDGraphs(dateDisplayFormate,res)
      }
    }
  }),
  on(dashboardAction.changeDMSVehicleDistanceTravelledGraphType,(state,{graphType})=>{
    
    return {
      ...state,
      dmsVehicleAnalytics:{
      ...state.dmsVehicleAnalytics,
      carDistanceMovedStats:changeDMSVehicleDistanceTravelledGraphType(graphType,state?.dmsVehicleAnalytics?.carDistanceMovedStats)
      }
    }
  }),
  
)