import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {NgrxSocketService} from './ngrx-socket.service';
import {SocketActions} from '.';
import {CONSTANT} from '../../config/constant';
import {loadAssetCountByType} from '../Assets/assets.action';
import {IAssetStatusSocketData} from './interfaces/custome.interfaces';
import { dashboardAction } from '../dashboard/dashboard';
import { IGpsData } from '../dashboard/interface';
import { NotificationActions } from '../Notification';
import { Notification } from '../Notification/interfaces/INotification.modal'
import { selectGeofences } from '../Fleet/fleet.selector';
import { loadGeofences, loadGeofencesSuccess } from '../Fleet/fleet.action';

@Injectable()
export class SocketEffects {
  getUpdatedAssetDataByTape$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.assetStatus),
      switchMap((action) => {
        const nsp = `${CONSTANT.ASSET_TYPES.SOCKET.NAMESPACE.UPDATE_ASSET_STATE}/${action.id}`;
        const topic = CONSTANT.ASSET_TYPES.SOCKET.TOPICS.LIVE;
        return from(this.ngrxSocket.get(nsp, topic)).pipe(
          switchMap((res: IAssetStatusSocketData) => {

            /*:TODO: :FIXME:*/
            /*
              *handle thie exception after backend is handled
            */
            if (res?.assetType === 'category') {
              return [];
            }
            if (res?.event === 'deleted' || res?.event === 'added') {
              return [
                SocketActions.assetStatusChange({res: res}),
                loadAssetCountByType({assetType: res['assetType']})
              ];
            } else if (res?.assetType) {
              return [
                SocketActions.assetStatusChange({res: res}),
                loadAssetCountByType({assetType: res['assetType']})
              ];
            }
            return [];
          }),
          catchError((error) => of(SocketActions.failure({error})))
        );
      })
    )
  );


  killAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.killAll),
      switchMap(() =>
        from(this.ngrxSocket.killAll().pipe(
          switchMap(() => [])
        ))
      )
    )
  );


  disconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.disconnect),
      switchMap((a) =>
        from(this.ngrxSocket.disconnect(a.nsp, a.topic, a.key).pipe(
          switchMap(() => [])
        ))
      )
    )
  );

  // Run this code when a gpsData action is dispatched
  socketGPSData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.gpsData),
      switchMap(() => {
        const nsp = CONSTANT.SOCKET.TRACKER.NAMESPACE.TRACKER_GPS;
        const topic = CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH;
        // Call the stores method, convert it to an observable
        return from(this.ngrxSocket.get(nsp, topic)).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res) => SocketActions.gpsDataSuccess({gps: res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error) => of(SocketActions.failure({error})))
        );
      })
    )
  );


  // run this action when  asset is created or updated
  disconnectGPSSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.disconnectGpsDataSocket),
      switchMap(() => {
        // Call the stores method, convert it to an observable
        const nameSpace = CONSTANT.SOCKET.TRACKER.NAMESPACE.TRACKER_GPS;
        const topic = CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH;
        return from(this.ngrxSocket.disconnect(nameSpace, topic)).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res: any) => SocketActions.connectionStats({status: res}))
        );
      })
    )
  );

  disconnectLMDSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.disconnectLmdLiveStatus),
      switchMap(() => {
        // Call the stores method, convert it to an observable
        const nameSpace = CONSTANT.SOCKET.LMD.NAMESPACE.DELIVERY;
        const topic = CONSTANT.SOCKET.LMD.TOPICS.LIVE_STATUS;
        return from(this.ngrxSocket.disconnect(nameSpace, topic)).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res: any) => SocketActions.connectionStats({status: res}))
        );
      })
    )
  );

  // Run this code when a lmdLiveStatus action is dispatched
  lmdDeivceLiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.lmdDeviceLiveStatus),
      switchMap(() => {
        // Call the stores method, convert it to an observable
        const nsp = CONSTANT.SOCKET.LMD.NAMESPACE.DELIVERY;
        const topic = CONSTANT.SOCKET.LMD.TOPICS.LIVE_STATUS;
        return from(this.ngrxSocket.get(nsp, topic)).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res: any) => SocketActions.lmdDeviceLiveStatusSuccess({liveStatus: res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error) => of(SocketActions.failure({error})))
        );
      })
    )
  );

  disconnectAssetUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocketActions.disconnectAssetStatus),
      switchMap((action) => {
        const nsp = `${CONSTANT.ASSET_TYPES.SOCKET.NAMESPACE.UPDATE_ASSET_STATE}/${action.id}`;
        const topic = CONSTANT.ASSET_TYPES.SOCKET.TOPICS.LIVE;
        return from(this.ngrxSocket.disconnect(nsp, topic)).pipe(
          map((res) => SocketActions.connectionStats({status: res}))
        );
      })
    );
  });

  updateWarehouseCountGraphWebSocketRequired$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocketActions.updateWarehouseCountGraphWebSocketRequired),
      switchMap((action) => {
        return from(this.ngrxSocket.get(action.nameSpace, action.topicName)).pipe(
          map((res: IGpsData) => dashboardAction.updateWarehouseZoneEchartGraphWebSocketSuccess({gpsData: res})
          ));
      })
    );
  });

  userNotificationWebSocketRequired$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocketActions.userNotificationWebSocketRequired),
      switchMap((action) => {
        return from(this.ngrxSocket.get(action.nameSpace, action.topicName)).pipe(
          map((res: Notification) => NotificationActions.updateNotificationList({notification: res})
          ));
      })
    );
  });

  updateGeofencesSocketRequired$ = createEffect(() =>{
    return this.actions$.pipe(
      ofType(SocketActions.updateGeofencesSocketRequired),
      switchMap((action) => {
        return from(this.ngrxSocket.get(action.nameSpace,action.topicName,action.key)).pipe(
          map((res) => {
            return SocketActions.updateGeofenceDataFromSocketSuccess({data : res})
          //  return loadGeofences({isLoadGeofence:true})
          })
  )}))});


  updateSocketDataRequired$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocketActions.updateSocketDataRequired),
      mergeMap((action) => {
        return from(this.ngrxSocket.get(action.nameSpace, action.topicName, action.key)).pipe(
          mergeMap((res: unknown) => {
            if (action.callback) {
              action.callback(res);
            }
              return [];
            }
          ));
      })
    );
  });
  updateGeofenceDataFromSocketSuccess$ = createEffect(() =>{
    return this.actions$.pipe(
      ofType(SocketActions.updateGeofenceDataFromSocketSuccess),
      withLatestFrom(this.store.select(selectGeofences)),
      switchMap(([action,stateData]) => {
        if(!action?.data?.isAccountLevel){
        let temp = [...stateData];
        if(action?.data?.action === 'added'){
          temp.push(action?.data?.data);
        }else if(action?.data?.action === 'updated'){
          const i = temp.findIndex(x=>x._id === action?.data?.data?._id);
          if( i >= 0){
            temp[i] = action?.data?.data
          }
        } else if(action?.data?.action === 'deleted'){
            const i = temp.findIndex(x=>x._id === action?.data?.data?._id);
            if( i >= 0){
              temp.splice(i, 1)
            }
        }
       return [loadGeofencesSuccess({geofences: temp})]
         }else {
          return [loadGeofences({isLoadGeofence : true})];
         } }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private ngrxSocket: NgrxSocketService
  ) {
  }
}
