import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MenuService} from 'app/core/menu/app.menu.service';
import {ConfirmationService} from 'primeng/api';
import {AppComponent} from 'app/app.component';
import {Router} from '@angular/router';
import {Ability} from '@casl/ability';
import {NotificationType} from 'app/shared/notification/notification-type';
import {Observable, Subscription} from 'rxjs';
import {UserProfile} from 'app/settings/user-profile/user-profile-type';
import {AccountsService} from 'services/accounts.service';
import {select, Store} from '@ngrx/store';
import {ProfileActions, ProfileSelectors} from 'state/Profile';
import {LoginActions, LoginSelectors} from 'state/Login';
import {LoginServiceService} from 'services/login-service.service';
import {DateFormatterService} from 'services/dateformat';
import moment from 'moment-timezone';
import {PrimeBannerMsgObj, PrimeMsgBanner} from 'app/shared/prime-msg-banner/prime-msg-banner-data-types';
import {CONSTANT} from 'config/constant';
import {unpackRules} from '@casl/ability/extra';
import {includes} from 'lodash';

import {Location} from '@angular/common';
import {deleteFirebaseToken} from 'state/User/user.action';
import {checkMobileView, loadAllAssetsCount} from 'state/Assets/assets.action';
import {SocketActions} from 'state/Socket';
import {selectSidebarState} from 'state/Sidebar/sidebar.selector';
import {getDefaultTableSettingsForAnAccount} from 'state/Settings/settings.action';
import {setSidebarSizeState} from 'state/Sidebar/sidebar.action';
import {getPushNotificationStatus} from 'state/Profile/profile.selector';
import {killAll} from 'state/Socket/socket.actions';
import {getKioskSubscriptionData} from '../../../state/Login/login.selector';
import {Actions, Subject} from '../../../auth/rules';
import {assetsCardsImageDisplayStatusSelector} from '../../../state/Assets/assets.selector';
import {getAssetCardsImageDisplayStatus} from '../../../state/Assets/assets.action';
import {getMsgBannerPosition} from '../../../state/Notification/notification.selector';
import {setTopMsgBannersCount} from '../../../state/Notification/notification.action';
import { NgrxSocketService } from '../../../state/Socket/ngrx-socket.service';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
  styleUrls: ['./app.main.component.scss'],
})
export class AppMainComponent implements OnInit, OnDestroy {
  topbarMenuActive: boolean;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: unknown = false;
  staticMenuMobileActive: boolean;
  menuClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  menuHoverActive: boolean;
  rightPanelActive: boolean;
  rightPanelClick: boolean;
  megaMenuActive: boolean;
  megaMenuClick: boolean;
  usermenuActive: boolean;
  usermenuClick: boolean;
  activeProfileItem: any;
  configActive: boolean;
  configClick: boolean;
  accountSettingsTabActive: boolean
  public notificationCount: number;
  public geoFenceNotificationObj: NotificationType;
  public userDetails: UserProfile;
  public roleAlphabet: string;
  private userProfileDetailsSub: Subscription;
  private switchAccSub: Subscription;
  public logo: string;
  msgObj: PrimeBannerMsgObj;
  input_configurations: PrimeMsgBanner;
  accountLicenseExpiryDate: any;
  public iotZenServerVersion$ = (this.store.select(LoginSelectors.getiotZenServerVersionNumber));
  public msgBannerPosition$ = (this.store.select(getMsgBannerPosition));
  public accountExpiryAlertObs: Observable<boolean>;
  public accountLicenseExpiryAlert = false;
  pushNotificationsEnabled$ = this.store.pipe(select(getPushNotificationStatus));
  licenceExpiryMsg: Observable<any>;
  splitMainLayout: unknown;

  kioskSubMsg:string;
  kioskSubAmount:number
  showQRCodeDialog:boolean
  qrCodeImageUrl:string
  triggerSupportPopup: number;
  isTabOpenInChrome: boolean = true

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 991) {
      this.store.dispatch(setSidebarSizeState({isFullScreen: true}));
    } else {
      this.store.dispatch(setSidebarSizeState({isFullScreen: false}));
    }

    this.store.dispatch(checkMobileView())
  }
  pollingIntervalId : any;
  constructor(
    private menuService: MenuService,
    public app: AppComponent,
    private router: Router,
    private ability: Ability,
    private accountsService: AccountsService,
    private confirmationService: ConfirmationService,
    private store: Store,
    private loginServiceService: LoginServiceService,
    private dateFormatter: DateFormatterService, private location: Location, private ngrxSocketService: NgrxSocketService,
    private configService: ConfigService
  ) {
    this.notificationCount = 0;
    this.geoFenceNotificationObj = {
      topic: 'geoFenceCheck',
      message: '',
      timeStamp: '',
    };
    this.setTimezoneToMoment();
  }
  isBannerVisible:boolean =true
  hasToStartPolling: boolean = false
  healthCheckUrl = this.configService.appConfig.appBaseUrl + 'healthCheckSystem/health'
  ngOnInit() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.isTabOpenInChrome = true
        this.hasToStartPolling && this.startPolling()
      } else {
        this.isTabOpenInChrome = false
      }
    })

    // this.startPolling(5000)

    this.store.select(selectSidebarState).subscribe(sidebarState => {
      this.splitMainLayout = sidebarState;
      this.staticMenuDesktopInactive = sidebarState;
    })
    this.store.dispatch(ProfileActions.getAllLanguages());
    const token = sessionStorage.getItem('token');
    const accountDetails = JSON.parse(sessionStorage.getItem('account'));

    if (token) {
      this.store.dispatch(LoginActions.setToken({token}));
      if (accountDetails)
        this.store.dispatch(LoginActions.setAccount({accountDetails}))
      this.store.dispatch(LoginActions.userAuthenticated());
      this.store.dispatch(loadAllAssetsCount());
      this.store.dispatch(getDefaultTableSettingsForAnAccount());
    }

    this.userProfileDetailsSub = this.store
      .select(ProfileSelectors.getUserProfileDetails)
      .subscribe((userDetails: UserProfile) => {
        if (userDetails) {
          this.userDetails = userDetails;
          if (this.userDetails && this.userDetails.username) {
            this.roleAlphabet = this.getAlphabetsToDisplay(this.userDetails);
          }
        }
      });

    // Fetch profile on user is logged in
    this.store.select(LoginSelectors.getToken).subscribe({
      next: (token) => {
        if (token) {
          this.checkOnlineStatus()
          this.store.dispatch(ProfileActions.getUserProfile());
          this.store.dispatch(ProfileActions.getUserLanguage());
        }
      },
    });

    this.checkLicense();
    this.store.dispatch(LoginActions.getSessionTimeoutTime());
    // check for mobile view
    this.store.dispatch(checkMobileView())


    this.store.select(getKioskSubscriptionData).subscribe(kioskSubscriptionData => {
      if(kioskSubscriptionData===null && this.ability.can(Actions.READ, Subject.KIOSK_MANAGEMENT)){
        this.store.dispatch(LoginActions.checkKioskSubscription())
      }else{
        this.checkKioskSubscription(kioskSubscriptionData);
      }
    })

    this.store.select(assetsCardsImageDisplayStatusSelector).subscribe(statusData => {
      if(token && !Object.keys(statusData)?.length){
        this.store.dispatch(getAssetCardsImageDisplayStatus())
      }
    })
    this.store.select(LoginSelectors.getInternetConnectivityState).subscribe((data:boolean)=>{
      !data && this.removeMsgBanner('internet-banner-msg')
       data && this.buildBannerMsg('error','No Internet','OOPS...No Internet! Check Your Internet Connection.',true,[],'internet-banner-msg');
    })

    this.store.select(LoginSelectors.getPrinterConnectivityState).subscribe((data:{status:string,message:string})=>{
      const {status,message} = data || {}
      if(status==='online'){
        this.removeMsgBanner('printer-error-msg')
      }else if(status==='offline'){
        this.buildBannerMsg('error','Printer Error',message,false,[],'printer-error-msg');
      }
    })

  }

  removeMsgBanner(key:string){
    const bannerMsgs = (this.input_configurations?.bannerMsgs || []).filter(({key:keyVal})=>keyVal!==key);
    if(this.msgBannerTypes[key]){
      delete this.msgBannerTypes[key]
    }
    this.input_configurations = { ...(this.input_configurations || {}), bannerMsgs };
    this.store.dispatch(setTopMsgBannersCount({count:bannerMsgs?.length}))
  }


  checkLicense() {
    this.accountExpiryAlertObs = this.store.select(LoginSelectors.getAccountLicenseExpiryAlert);
    this.licenceExpiryMsg = this.store.select(LoginSelectors.getAccountLicenseExpiryAlertMsg);

    this.licenceExpiryMsg.subscribe((MsgData) => {
      if(!MsgData?.accountLicenseExpiryAlert)return;
      const dateString = MsgData.accountLicenseExpiryDate;
      const date = new Date(dateString);
      const msg = MsgData.licenceExpiryMsg.replace('__licenseExpiryDate__', date);
      this.buildBannerMsg('error','Account Expiry Alert',msg,true,[],'expiry-alert');
    });
  }

  setTimezoneToMoment() {
    if (!this.userDetails) {
      const tz = sessionStorage.getItem('timezone');
      if (tz) {
        moment.tz.setDefault(tz);
      } else {
        this.dateFormatter.getUserTimezone().then((res) => {
          if (res?.timezone) {
            moment.tz.setDefault(res.timezone);
          }
        });
      }
    }
  }

  getAlphabetsToDisplay(userDetails) {
    let alphabetsToDisplay: string;
    alphabetsToDisplay = '';

    if (userDetails.firstName) {
      alphabetsToDisplay = userDetails.firstName.charAt(0);
    }
    if (userDetails.lastName) {
      alphabetsToDisplay += userDetails.lastName.charAt(0);
    }
    if (!userDetails.firstName && !userDetails.lastName) {
      if (userDetails.roles.length > 0) {
        alphabetsToDisplay = userDetails.roles[0].charAt(0);
      }
    }
    return alphabetsToDisplay;
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.usermenuClick && this.isSlim()) {
      this.usermenuActive = false;
      this.activeProfileItem = null;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.menuService.reset();
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.configClick = false;
    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
    this.megaMenuClick = false;
    this.usermenuClick = false;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.app.layoutMode === 'overlay') {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (!this.isDesktop()) {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }
    if (event) {
      if (this.isDesktop())
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      event.preventDefault();
    }
  }

  onMenuClick() {
    this.menuClick = true;
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    if (event) {
      event.preventDefault();
    }
  }

  onTopbarSupportButtonClick(event){
    this.triggerSupportPopup = Math.random();
    if (event) {
      event.preventDefault();
    }
  }

  accountSettings() {
    this.router.navigate(['accountLevelSettings']);
    this.accountSettingsTabActive = true
  }

  async onTopbarItemClick(event, item, type = '') {
    this.accountSettingsTabActive = false
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    switch (type) {
      case 'profile':
        await this.router.navigate(['config/userProfile']);
        this.onTopbarMenuButtonClick('');
        break;
      case 'logout':
        await this.logout();

        break;
    }

    event.preventDefault();
  }

  async logout() {
    if (sessionStorage.getItem('token')) {
      this.store.dispatch(deleteFirebaseToken());
      this.store.dispatch(killAll());
      const accountDetails = JSON.parse(sessionStorage.getItem('account'));
      if (accountDetails?.['assetTypes']) {
        this.store.dispatch(SocketActions.disconnectAssetStatus({id: accountDetails['_id']}));
      }
    }
    this.roleAlphabet = '';
    this.userDetails = null;
    /*clearing rules*/
    this.ability.update([]);
    await this.router.navigate(['login']);
    setTimeout(() => {
      this.clearSessionStorageOnRedirectToLoginPage();
      document.cookie = "firebaseDocId=" + null;
      window.location.reload();
    }, 0);
    this.accountLicenseExpiryAlert = false;
  }

  onTopbarSubItemClick(event: MouseEvent, type = '', id = '', popupKey?: string) {
    event.preventDefault();
    event.stopPropagation();

    if (type === 'accounts') {
      this.confirm(event, id, popupKey);
    }
  }

  switchAcc(id: string) {
    if (sessionStorage.getItem('token')) {
      this.store.dispatch(deleteFirebaseToken());
      this.store.dispatch(killAll());
      const accountDetails = JSON.parse(sessionStorage.getItem('account'));
      if (accountDetails?.['assetTypes']) {
        this.store.dispatch(SocketActions.disconnectAssetStatus({id: accountDetails['_id']}));
      }
    }

    const subscribe = {
      next: (data) => {
        this.clearSessionStorageOnRedirectToLoginPage();
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('account', JSON.stringify(data.account));
        if (sessionStorage.getItem('token')) {
          const accountDetails = JSON.parse(sessionStorage.getItem('account'));
          if (accountDetails?._id) {
            this.store.dispatch(SocketActions.assetStatus({id: accountDetails['_id']}));
          }
        }

        if (this.router.url && includes(this.router.url, '/asset/')) {
          this.location.go('/asset');
        }

        const result = {
          next: (roleData: any) => {
            const rI = roleData.roleInfo;
            if (rI && rI.ability && rI.landingPage) {
              this.setRules(unpackRules(rI.ability), rI.landingPage);
              window.location.reload();
            }
          },
          error: console.error,
          complete: console.log
        }

        this.accountsService.getRoleInfo().subscribe(result);
      },
      error: console.error,
      complete: console.log
    }

    this.switchAccSub = this.accountsService.switchAccount(id).subscribe(subscribe);
  }

  setRules(abilityFound: any, landingPage: any) {
    this.ability.update(abilityFound);
    sessionStorage.setItem('rules', JSON.stringify(this.ability.rules));
    this.routeToLandingPage(landingPage);
  }

  routeToLandingPage(landingPage: any) {
    switch (landingPage) {
      case CONSTANT.CASL_LANDING_PAGES.ASSET:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.ASSET]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.GROUP:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.GROUP]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.INSPECTION:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.INSPECTION]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.SECURITY:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.SECURITY]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.SERVICE:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.SERVICE]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.SETTINGS:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.SETTINGS]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.TRACKER:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.TRACKER]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.DIGITAL_KEY_QUEUE:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.DIGITAL_KEY_QUEUE]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.DIGITAL_KEY_MANAGE:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.DIGITAL_KEY_MANAGE]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.O2D_ORDER:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.O2D_ORDER]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.LMD_ORDER:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.LMD_ORDER]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.LMD_DASHBOARD:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.LMD_DASHBOARD]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.MMD_CONSIGNMENT:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.MMD_CONSIGNMENT]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.MMD_DASHBOARD:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.MMD_DASHBOARD]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.KIOSK_DASHBOARD:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.KIOSK_DASHBOARD]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.KIOSK_KDS:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.KIOSK_KDS]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.KIOSK_ORDER:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.KIOSK_ORDER]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.KIOSK_STORE:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.KIOSK_STORE]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.KIOSK_TODAY:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.KIOSK_TODAY]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.WAREHOUSE_DASHBOARD:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.WAREHOUSE_DASHBOARD]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.WAREHOUSE_TRACKER:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.WAREHOUSE_TRACKER]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.WAREHOUSE_ANALYTICS:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.WAREHOUSE_ANALYTICS]).then(r => r);
        break;
      case CONSTANT.CASL_LANDING_PAGES.MMD_CONSIGNMENT_ADD:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.MMD_CONSIGNMENT], {queryParams: {page: 'add_consignments'}}).then(r => r);
        break;
      default:
        this.router.navigate([CONSTANT.CASL_ROUTING_LINKS.ASSET]).then(r => r);
        break;
    }
  }

  clearSessionStorageOnRedirectToLoginPage() {
    if (sessionStorage.getItem('token')) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userRoles');
      sessionStorage.removeItem('rules');
      sessionStorage.removeItem('isAccountHasLicensing');
      sessionStorage.removeItem('accountLicenseExpiryAlert');
      sessionStorage.removeItem('accountLicenseExpiryDate');
      sessionStorage.removeItem('accountLicenseMessage');
      localStorage.removeItem('KIOSK_STORE_LOCATION');
      this.ability.update([]);
    }
  }

  confirm(event: Event, id: string, popupKey?: string) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to change account?',
      key: popupKey,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // confirm action
        this.switchAcc(id);
      },
      reject: () => {
        // reject action
      },
    });
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }

  onRightPanelClick() {
    this.rightPanelClick = true;
  }

  onMegaMenuButtonClick(event) {
    this.megaMenuClick = true;
    this.megaMenuActive = !this.megaMenuActive;
    event.preventDefault();
  }

  onMegaMenuClick() {
    this.megaMenuClick = true;
    this.megaMenuActive = false;
  }

  // onThingsItemClick(item){
  //   console.log(item)
  //   const liContianer =item.target.closest('li')
  //   liContianer.classList.add('active')
  // }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  onRippleChange(event) {
    this.app.ripple = event.checked;
  }

  onConfigClick() {
    this.configClick = true;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isHorizontal() {
    return this.app.layoutMode === 'horizontal';
  }

  isSlim() {
    return this.app.layoutMode === 'slim';
  }

  isOverlay() {
    return this.app.layoutMode === 'overlay';
  }

  ngOnDestroy() {
    this.userProfileDetailsSub?.unsubscribe();
    this.switchAccSub?.unsubscribe();
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId);
    }
  }

  buildRoutesForAssetTypes(assetTypes) {
    this.loginServiceService.buildRoutesForAssetTypes(assetTypes);
  }

//   prime ng message banner
  buildBannerMsg(severity,summary,detail,closable,additionalActionIcons,key) {
    if(this.msgBannerTypes[key])return;
    this.msgBannerTypes = {...(this.msgBannerTypes || {}),[key]:true}
    this.msgObj = {severity,summary,detail,closable,additionalActionIcons,key};
    const bannerMsgs = [...(this.input_configurations?.bannerMsgs || []), this.msgObj]
    this.store.dispatch(setTopMsgBannersCount({count:bannerMsgs?.length}))
    this.input_configurations = { ...(this.input_configurations || {}), bannerMsgs };
  }

  msgBannerTypes={}

  recieveAction(action :string){
  this.showQRCodeDialog = action==='qr-code'

    this.showQRCodeDialog &&
  this.loginServiceService.getKioskSubQrCode().subscribe((qrCodeData:IQRCodeData)=>{
    this.qrCodeImageUrl = qrCodeData?.data ? `data:image/png;base64,${qrCodeData?.data}` : qrCodeData?.image_url || ''
    this.kioskSubAmount = qrCodeData?.amount || 0
  });
  }

  checkKioskSubscription(kioskSubscriptionData) {
    const additionalActions=[
      {icon:'pi pi-qrcode',key:'qr-code',tooltip:'Payment by QR Code'},
      // {icon:'pi pi-link',key:'link',tooltip:'Click to Make Payment by Link'}
    ]

    if(kioskSubscriptionData?.isPaymentDue){
      this.kioskSubMsg = kioskSubscriptionData?.message || ''
      this.buildBannerMsg('error','Subscription Due Alert',this.kioskSubMsg,false,additionalActions,'subscription-msg');
    }
    else{
      this.kioskSubMsg=''
    }
  }

  checkOnlineStatus = async () => {
    try {
      // return await new Promise((resolve, reject) => {
        this.ngrxSocketService.checkStatus('/').subscribe(
          (status) => {
            console.log("Connection for Check Internet Status", status);
            if (status) {
              this.isBannerVisible = false;
              this.store.dispatch(LoginActions.isBannerVisibleForInternetConfig({ value: false }));
              if (this.pollingIntervalId) {
                clearInterval(this.pollingIntervalId);
                this.pollingIntervalId = null;
                this.hasToStartPolling = false
              }
            } else {
              if (!this.pollingIntervalId) {
                this.hasToStartPolling = true
                this.isTabOpenInChrome && this.startPolling();
              }
            }
          },
          (error) => {
            console.error('Socket status check error:', error);
            this.isBannerVisible = false;
            this.store.dispatch(LoginActions.isBannerVisibleForInternetConfig({ value: false }));
          }
        );
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  checkStatus = async () => {
    try {
      await fetch(this.healthCheckUrl, {
        method: 'GET',
        redirect: 'follow',
        mode: 'no-cors'
      });
      return true;
    } catch (_) {
      return false;
    }
  };

  startPolling() {
    this.pollingIntervalId = setInterval(async () => {
      const status = await this.checkStatus();
      if (!status) {
        this.isBannerVisible = true;
        this.store.dispatch(LoginActions.isBannerVisibleForInternetConfig({ value: true }));
      } else {
        this.isBannerVisible = false;
        this.store.dispatch(LoginActions.isBannerVisibleForInternetConfig({ value: false }));
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
          this.hasToStartPolling = false
        }
      }
    }, 5000);
  }
}

interface IQRCodeData {
  image_url:string;
  amount:number,
  data:string
}
